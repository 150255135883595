import { useMemo } from 'react';

import LoanAccount from './LoanAccount';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { NotFound } from '../NotFound/NotFound';
import { useParamsAccountId } from './utils/useParamsAccountId';
import { DataWithState } from '@api/models/common';
import { LoanResponse, AccountsResponse } from '../../types/generated/pos';

interface Props {
	accountsState: DataWithState<AccountsResponse>;
}

function LoanAccountPage(props: Props) {
	const { accountsState } = props;
	const accountId = useParamsAccountId();

	const loan: LoanResponse = useMemo(() => {
		return accountsState.data?.loans.find((currLoan) => {
			return accountId === currLoan.loanId;
		}) as LoanResponse;
	}, [accountId, accountsState.data]);

	if (accountsState.loading) {
		return <LoadingScreen />;
	}

	if (!loan) {
		return <NotFound />;
	}

	return <LoanAccount loan={loan} />;
}

export default LoanAccountPage;
