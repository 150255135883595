import { DependencyList, useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { normalizeError } from '@api/common/normalizeError';
import { type FetchOptions, requestHttp } from '@api/common/requestHttp';
import type { DataWithState } from '@api/models/common';
import type { ErrorResponse } from '../../../types/generated/pos';

export const useFetch = <OUTPUT>(
	url: string | null,
	options?: FetchOptions,
	deps?: DependencyList[number] | null
): DataWithState<OUTPUT> => {
	const [data, setData] = useState<DataWithState<OUTPUT>>({
		data: null,
		loading: true,
		error: '',
	});

	const accessToken = window.accessToken;
	const { logout, getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		if (!accessToken || !url) {
			return;
		}

		requestHttp(url, accessToken, getAccessTokenSilently, logout, options)
			.then((response: Response) => {
				if (response.ok) {
					return response.json() as Promise<OUTPUT>;
				}

				throw new Error('Something went wrong.');
			})
			.then((parsedData) => {
				setData({ data: parsedData, loading: false, error: '' });
			})
			.catch((error) => {
				setData({ data: null, loading: false, error: error as string });
			});
	}, [url, deps]);

	return data;
};

type Request<INPUT> = (input: INPUT) => void;
export const useMutate = <OUTPUT, INPUT>(
	url: string | null,
	method: 'GET' | 'POST' = 'POST'
): [DataWithState<OUTPUT>, Request<INPUT>] => {
	const [data, setData] = useState<DataWithState<OUTPUT>>({
		data: null,
		loading: false,
		error: '',
	});

	const accessToken = window.accessToken;
	const { logout, getAccessTokenSilently } = useAuth0();

	const mutate = useCallback(
		(input: INPUT) => {
			if (!accessToken || !url) {
				return;
			}

			setData({ data: null, loading: true, error: '' });

			const options = {
				method,
				//TODO дописать логику передачи параметров в урле для гет-запросов
				body: JSON.stringify(input),
				headers: {
					'Content-Type': 'application/json',
				},
			};

			requestHttp(url, accessToken, getAccessTokenSilently, logout, options)
				.then(async (response: Response) => {
					const result = (await response.json()) as OUTPUT;

					if (response.ok) {
						setData({ data: result, loading: false, error: '' });
					} else {
						setData({
							data: null,
							loading: false,
							error: normalizeError(result),
						});
					}
				})
				.catch((error) => {
					setData({
						data: null,
						loading: false,
						error: {
							message: 'Something went wrong.',
							errorDetails: error as ErrorResponse['errorDetails'],
							errorType: 'UNKNOWN',
						},
					});
				});
		},
		[accessToken, logout, getAccessTokenSilently, url, method]
	);

	return [data, mutate];
};
