import {
	PaymentsRepaymentsProvidersListData,
	RepaymentProvider,
} from '../../../types/generated/pos';

export const flatProviders = (
	providerList: PaymentsRepaymentsProvidersListData['providers']
): RepaymentProvider[] => {
	return (providerList || [])
		?.flatMap((providerInfo) => providerInfo.providers || [])
		.filter((provider): provider is RepaymentProvider => !!provider);
};
