export enum PaymentNotificationStates {
	Congratulations = 'Congratulations',
	NDaysOrLess_BeforeDueDate_NotPaid = 'NDaysOrLess_BeforeDueDate_NotPaid',
	NDaysOrLess_BeforeDueDate_PartialPaid = 'NDaysOrLess_BeforeDueDate_PartialPaid',
	Today_DueDate_NotPaid = 'Today_DueDate_NotPaid',
	Today_DueDate_PartialPaid = 'Today_DueDate_PartialPaid',
	PartialPayment_MoreThan7daysBeforeDueDate = 'PartialPayment_MoreThan7daysBeforeDueDate',
	NoMissedPayments_MoreThan7daysBeforeDueDate = 'NoMissedPayments_MoreThan7daysBeforeDueDate',
	PaidFull_BeforeDueDate = 'PaidFull_BeforeDueDate',
	PaidMore_BeforeDueDate = 'PaidMore_BeforeDueDate',
	MissedPayment_NoFee = 'MissedPayment_NoFee',
	MissedPayment_Fee = 'MissedPayment_Fee',
	NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment = 'NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment',
	NeedToPayFee_MoreThan7daysBeforeDueDate = 'NeedToPayFee_MoreThan7daysBeforeDueDate',
	NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid = 'NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid',
	LoanClosed = 'LoanClosed',
}

export enum PaymentNotificationStatesRBB {
	New = 'New',
	UpcomingNotPaid = 'UpcomingNotPaid',
	UpcomingPartialPaid = 'UpcomingPartialPaid',
	Today = 'Today',
	TodayPartialPaid = 'TodayPartialPaid',
	PartialPaid = 'PartialPaid',
	NoMissedPayments = 'NoMissedPayments',
	PaidFull = 'PaidFull',
	PaidMore = 'PaidMore',
	MissedPayment = 'MissedPayment',
	MissedPaymentAndFee = 'MissedPaymentAndFee',
	LoanClosed = 'LoanClosed',
	MissedPaymentAndFeeUpcoming = 'MissedPaymentAndFeeUpcoming',
	MissedFee = 'MissedFee',
	MissedFeeUpcoming = 'MissedFeeUpcoming',
}

export type PaymentStatusRBBMapperInterface = {
	[key in PaymentNotificationStatesRBB]: keyof typeof PaymentNotificationStates;
};

export const paymentStatusRBBMapper: PaymentStatusRBBMapperInterface = {
	New: 'Congratulations',
	UpcomingNotPaid: 'NDaysOrLess_BeforeDueDate_NotPaid',
	UpcomingPartialPaid: 'NDaysOrLess_BeforeDueDate_PartialPaid',
	Today: 'Today_DueDate_NotPaid',
	TodayPartialPaid: 'Today_DueDate_PartialPaid',
	PartialPaid: 'PartialPayment_MoreThan7daysBeforeDueDate',
	NoMissedPayments: 'NoMissedPayments_MoreThan7daysBeforeDueDate',
	PaidFull: 'PaidFull_BeforeDueDate',
	PaidMore: 'PaidMore_BeforeDueDate',
	MissedPayment: 'MissedPayment_NoFee',
	MissedPaymentAndFee: 'MissedPayment_Fee',
	LoanClosed: 'LoanClosed',
	MissedPaymentAndFeeUpcoming:
		'NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment',
	MissedFee: 'NeedToPayFee_MoreThan7daysBeforeDueDate',
	MissedFeeUpcoming: 'NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid',
};
