import { useEffect } from 'react';
import { track } from '@amplitude/analytics-browser';

import { AccountStatuses, LoanTypes } from '@api/models/account';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { LoanResponse } from '../../../types/generated/pos';
import { getLoanEventOptions } from './eventOptions';
import { paymentStatusRBBMapper } from '@api/models/notificationStates';

const LoanTypeToEvent = {
	[LoanTypes.POS]: AmplitudeEvents.POSMain_Opened,
	[LoanTypes.CASH]: AmplitudeEvents.CashloanMain_Opened,
};

export const useTrackLoanEvents = (loan: LoanResponse) => {
	useEffect(() => {
		if (loan) {
			const eventOptions = getLoanEventOptions(loan);

			const amplitudeEvent = LoanTypeToEvent[loan.loanType];

			const loanType = loan.state?.type;

			if (loan.status === AccountStatuses.CLOSED) {
				track(amplitudeEvent, {
					state: paymentStatusRBBMapper.LoanClosed,
					product: eventOptions,
				});
			} else if (loanType !== undefined) {
				track(amplitudeEvent, {
					state: paymentStatusRBBMapper[loanType],
					product: eventOptions,
				});
			}
		}
	}, [loan]);
};
