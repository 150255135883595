import RedUnion from '@assets/icons/RedUnion.svg';
import GreenUnion from '@assets/icons/GreenUnion.svg';
import YellowUnion from '@assets/icons/YellowUnion.svg';

export const enum PermittedNotificationTypes {
	BAD_NOTIFICATION = 'BAD_NOTIFICATION',
	SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION',
	WARNING_NOTIFICATION = 'WARNING_NOTIFICATION',
}

export const notificationConfig: {
	[key in PermittedNotificationTypes]: { [k: string]: string };
} = Object.freeze({
	BAD_NOTIFICATION: {
		bgColor: 'rgba(244, 87, 37, 0.12)',
		icon: RedUnion,
	},
	SUCCESS_NOTIFICATION: {
		bgColor: 'rgba(74, 201, 155, 0.12)',
		icon: GreenUnion,
	},
	WARNING_NOTIFICATION: {
		bgColor: 'rgba(255, 199, 0, 0.12)',
		icon: YellowUnion,
	},
});
