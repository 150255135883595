import styled from '@emotion/styled';

const ListWrapper = styled.div`
	border: 1px solid rgba(215, 215, 215, 1);
	padding: 16px;
	border-radius: 24px;
`;
const List = styled.ol`
	margin: 0 0 8px;
	padding-left: 16px;
`;
const ListItem = styled.li`
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
`;
const ListText = styled.p`
	//styleName: Desktop/Body S;
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
`;

const howToPayItems = [
	'Download this QR Ph code on your device’s photo gallery',
	'Open GCash or any other bank app you’ll use to make the repayment',
	"Use the app's QR payment feature to upload or scan the saved QR Ph code and complete the payment",
];

const tip =
	'Tip: You can also forward this QR for someone else to make a payment for your Salmon';

const HowToPayList = () => {
	return (
		<ListWrapper>
			<List>
				{howToPayItems.map((text, index) => (
					<ListItem key={index}>{text}</ListItem>
				))}
			</List>
			<ListText>{tip}</ListText>
		</ListWrapper>
	);
};
export default HowToPayList;
