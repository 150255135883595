import {
	CreditLineStateResponse,
	CreditLineStateResponseNotPaidDueLessThan7DaysTypeEnum,
	CreditLineStateResponseNotPaidDueMissedTypeEnum,
	CreditLineStateResponseNotPaidDueTodayTypeEnum,
	CreditLineStateResponseNotPaidDueTomorrowTypeEnum,
	CreditLineStateResponseNotPaidDueMoreThan7DaysTypeEnum,
	Money,
} from '../../../types/generated/pos';

export const getCreditMinPayment = (
	creditStatus: CreditLineStateResponse
): Money | undefined => {
	const { type } = creditStatus;

	switch (type) {
		case CreditLineStateResponseNotPaidDueMoreThan7DaysTypeEnum.NotPaidDueMoreThan7Days:
		case CreditLineStateResponseNotPaidDueLessThan7DaysTypeEnum.NotPaidDueLessThan7Days:
		case CreditLineStateResponseNotPaidDueMissedTypeEnum.NotPaidDueMissed:
		case CreditLineStateResponseNotPaidDueTodayTypeEnum.NotPaidDueToday:
		case CreditLineStateResponseNotPaidDueTomorrowTypeEnum.NotPaidDueTomorrow:
			return creditStatus.minPayment;
		default:
			return undefined;
	}
};
