/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountInfo {
	financialInstitution?: FinancialInstitution;
	id?: string;
	status?: string;
	type?: string;
}

export interface AccountRequisites {
	accountName: string;
	accountNumber: string;
}

export enum AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum AccountTypeEnum1 {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum AccountTypeEnum10 {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
}

export enum AccountTypeEnum2 {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum AccountTypeEnum3 {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
}

export enum AccountTypeEnum4 {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum AccountTypeEnum5 {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum AccountTypeEnum6 {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum AccountTypeEnum7 {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
}

export enum AccountTypeEnum8 {
	FINANCING = 'FINANCING',
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum AccountTypeEnum9 {
	DEBIT = 'DEBIT',
}

export interface AccountsResponse {
	creditLine?: CreditLineResponse;
	creditLines?: CreditLineResponse[];
	debit: DebitAccountInfoResponse[];
	loans: LoanResponse[];
	savings: SavingsAccountInfoResponse[];
}

export interface AccountsV2Response {
	accounts: (
		| FinancingAccountV2Response
		| CreditLineAccountV2Response
		| DebitAccountV2Response
		| SavingsAccountV2Response
	)[];
	warnings?: Warnings;
}

export interface ActivateCardRequest {
	/** Hash карты на пластике */
	hash: string;
}

export interface ActivateMatchmoveCardRequest {
	assocNumber: string;
	lastFour: string;
}

export interface ApplicationResponse {
	applicationNumber: string;
	applicationStatus: ApplicationResponseApplicationStatusEnum;
}

export enum ApplicationResponseApplicationStatusEnum {
	PROCESS = 'PROCESS',
	DOCUMENT_SIGNED = 'DOCUMENT_SIGNED',
	LOAN_ISSUE = 'LOAN_ISSUE',
}

export interface AvailableBalanceLimitExceededInfo {
	amount: Money;
	fee: Money;
	limitType: AvailableBalanceLimitExceededInfoLimitTypeEnum;
}

export enum AvailableBalanceLimitExceededInfoLimitTypeEnum {
	AvailableBalance = 'AvailableBalance',
}

export interface BAFResponse {
	referralCode: string;
	referralMessage: string;
}

export type BadRequest = ErrorResponse & {
	errorType?: BadRequestErrorTypeEnum;
	/** @example "Some kind of message descriping what is wrong" */
	message?: string;
};

export enum BadRequestErrorTypeEnum {
	ExceptionsDefault = 'exceptions.default',
}

export interface Basket {
	/** @format int64 */
	amount: number;
}

export type BlockCardData = object;

export interface BlockCardRequest {
	reason: BlockCardRequestReasonEnum;
}

export enum BlockCardRequestReasonEnum {
	LOST = 'LOST',
	DAMAGED = 'DAMAGED',
	STOLEN = 'STOLEN',
}

export interface CardExpirationDate {
	month: string;
	year: string;
}

export type CardIsAlreadyActive = ErrorResponse & {
	errorType?: CardIsAlreadyActiveErrorTypeEnum;
	/** @example "Card is already linked and active" */
	message?: string;
};

export enum CardIsAlreadyActiveErrorTypeEnum {
	PosapiCardExceptionCardIsAlreadyActive = 'posapi.CardException.CardIsAlreadyActive',
}

export interface CardProperties {
	isActive: boolean;
	isEcom: boolean;
	isLinked: boolean;
	isPinSet: boolean;
	isWithdrawal: boolean;
}

export interface CardRequisites {
	expirationDate: CardExpirationDate;
	pan: string;
	securityCode: string;
}

export interface CardResponse {
	accountId: string;
	cardDesignId: string;
	/** @format uuid */
	cardId: string;
	/** Маскированный номер карты */
	panMasked?: string;
	paymentSystem: CardResponsePaymentSystemEnum;
	properties: CardProperties;
	status: CardResponseStatusEnum;
	tariffId: string;
	type: CardResponseTypeEnum;
}

export enum CardResponsePaymentSystemEnum {
	MASTERCARD = 'MASTERCARD',
	VISA = 'VISA',
	UPI = 'UPI',
}

export enum CardResponseStatusEnum {
	ISSUED = 'ISSUED',
	ACTIVE = 'ACTIVE',
	BLOCKED = 'BLOCKED',
	SUSPENDED = 'SUSPENDED',
	EXPIRED = 'EXPIRED',
}

export enum CardResponseTypeEnum {
	PLASTIC = 'PLASTIC',
	DIGITAL = 'DIGITAL',
}

export interface CardsResponse {
	cards: CardResponse[];
}

export interface ChallengesResponse {
	qrChallengePassed: boolean;
	tutorialChallengePassed: boolean;
}

export interface CompletionCashConfirmRequest {
	code: string;
	confirmationId: string;
}

export interface CompletionCashConfirmResponse {
	confirmationTicketId: string;
}

export type CompletionCashConfirmationsData = CompletionCashConfirmResponse;

export type ConfirmMagicLinkCreateData = any;

export type ConfirmOtpCreateData = TransferResponse;

export interface ConfirmationCompleteRequest {
	code?: string;
	ticketId?: string;
}

export type ConfirmationErrorResponse = ErrorResponse & {
	/** @example {"ticketId":"ticketId"} */
	errorDetails?: any;
	errorType?: ConfirmationErrorResponseErrorTypeEnum;
	/** @example "Already confirmed ticketId=ticketId" */
	message?: string;
};

export enum ConfirmationErrorResponseErrorTypeEnum {
	ConfirmationsConfirmationExceptionAlreadyConfirmed = 'confirmations.ConfirmationException.AlreadyConfirmed',
	ConfirmationsConfirmationExceptionInvalidCode = 'confirmations.ConfirmationException.InvalidCode',
	ConfirmationsConfirmationExceptionTicketExpired = 'confirmations.ConfirmationException.TicketExpired',
	ConfirmationsConfirmationExceptionAttemptsExceeded = 'confirmations.ConfirmationException.AttemptsExceeded',
	ConfirmationsConfirmationExceptionTicketNotFound = 'confirmations.ConfirmationException.TicketNotFound',
}

export interface ConfirmationMagicLinkRequest {
	ticketId?: string;
}

export interface ConfirmationRequest {
	code: string;
	/**
	 * ticketId from error response NeedConfirmationError
	 * @format uuid
	 * @example "5bde3299-7281-40da-a841-0d41833a95be"
	 */
	ticketId: string;
}

export interface ConfirmationTicketStartResponse {
	/**
	 * ticketId for confirmation request
	 * @format uuid
	 * @example "5bde3299-7281-40da-a841-0d41833a95be"
	 */
	id?: string;
}

export type ConflictErrorResponse = ErrorResponse & {
	errorType?: ConflictErrorResponseErrorTypeEnum;
	/** @example "Conflict" */
	message?: string;
};

export enum ConflictErrorResponseErrorTypeEnum {
	ConfirmationsConfirmationExceptionConflict = 'confirmations.ConfirmationException.Conflict',
}

export type CreateCashApplicationData = NewApplicationResponse;

export interface CreditLineAccountV2Response {
	balance: {
		available: Money;
		outstanding: Money;
	};
	cards: CardResponse[];
	/** @format date */
	dueDate: string;
	eligibleForCard: boolean;
	financialInstitution: FinancialInstitution;
	id: string;
	limit: Money;
	status: CreditLineAccountV2ResponseStatusEnum;
	tariffId: string;
	type: CreditLineAccountV2ResponseTypeEnum;
}

export enum CreditLineAccountV2ResponseStatusEnum {
	ACTIVE = 'ACTIVE',
	OVERDUE = 'OVERDUE',
}

export enum CreditLineAccountV2ResponseTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
}

export interface CreditLineContractListData {
	/** @example "Pre signed s3 file irl" */
	location?: string;
}

export type CreditLineListData = CreditLineResponse;

export interface CreditLinePaymentsUpcomingResponse {
	accountId: string;
	/** @format date */
	dueDate: string;
	minPayment?: Money;
	outstandingBalance: Money;
	/**
	 * - `OVERDUE`: The payment is overdue.
	 *
	 * - `CURRENT`: The next payment is due either today or within the next 7 days.
	 *
	 * - `PENDING`: The next payment is due in more than 7 days.
	 */
	status: UpcomingPaymentStatus;
	type: CreditLinePaymentsUpcomingResponseTypeEnum;
}

export enum CreditLinePaymentsUpcomingResponseTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
}

export interface CreditLineResponse {
	accountId: string;
	accountType: CreditLineResponseAccountTypeEnum;
	balance: {
		available: Money;
		outstanding: Money;
	};
	cards: CardResponse[];
	/**
	 * Дью дата. Если мин.платеж еще не сформирован, будет null
	 * @format date
	 */
	dueDate?: string;
	eligibleForCard: boolean;
	financialInstitution: FinancialInstitution;
	limit: Money;
	status: CreditLineResponseStatusEnum;
}

export enum CreditLineResponseAccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
}

export enum CreditLineResponseStatusEnum {
	ACTIVE = 'ACTIVE',
	DISABLED = 'DISABLED',
}

export type CreditLineStateListData = CreditLineStateResponse;

export type CreditLineStateResponse =
	| CreditLineStateResponseNew
	| CreditLineStateResponseNoOutstandingBalance
	| CreditLineStateResponseWaitingStatement
	| CreditLineStateResponseNotPaidDueMoreThan7Days
	| CreditLineStateResponseNotPaidDueLessThan7Days
	| CreditLineStateResponseNotPaidDueTomorrow
	| CreditLineStateResponseNotPaidDueToday
	| CreditLineStateResponseNotPaidDueMissed;

export interface CreditLineStateResponseNew {
	type: CreditLineStateResponseNewTypeEnum;
}

export enum CreditLineStateResponseNewTypeEnum {
	New = 'New',
}

export interface CreditLineStateResponseNoOutstandingBalance {
	type: CreditLineStateResponseNoOutstandingBalanceTypeEnum;
}

export enum CreditLineStateResponseNoOutstandingBalanceTypeEnum {
	NoOutstandingBalance = 'NoOutstandingBalance',
}

export interface CreditLineStateResponseNotPaidDueLessThan7Days {
	/** @format date */
	dueDate: string;
	minPayment: Money;
	type: CreditLineStateResponseNotPaidDueLessThan7DaysTypeEnum;
}

export enum CreditLineStateResponseNotPaidDueLessThan7DaysTypeEnum {
	NotPaidDueLessThan7Days = 'NotPaid_DueLessThan7Days',
}

export interface CreditLineStateResponseNotPaidDueMissed {
	/** @format date */
	dueDate: string;
	minPayment: Money;
	type: CreditLineStateResponseNotPaidDueMissedTypeEnum;
}

export enum CreditLineStateResponseNotPaidDueMissedTypeEnum {
	NotPaidDueMissed = 'NotPaid_DueMissed',
}

export interface CreditLineStateResponseNotPaidDueMoreThan7Days {
	/** @format date */
	dueDate: string;
	minPayment: Money;
	type: CreditLineStateResponseNotPaidDueMoreThan7DaysTypeEnum;
}

export enum CreditLineStateResponseNotPaidDueMoreThan7DaysTypeEnum {
	NotPaidDueMoreThan7Days = 'NotPaid_DueMoreThan7Days',
}

export interface CreditLineStateResponseNotPaidDueToday {
	/** @format date */
	dueDate: string;
	minPayment: Money;
	type: CreditLineStateResponseNotPaidDueTodayTypeEnum;
}

export enum CreditLineStateResponseNotPaidDueTodayTypeEnum {
	NotPaidDueToday = 'NotPaid_DueToday',
}

export interface CreditLineStateResponseNotPaidDueTomorrow {
	/** @format date */
	dueDate: string;
	minPayment: Money;
	type: CreditLineStateResponseNotPaidDueTomorrowTypeEnum;
}

export enum CreditLineStateResponseNotPaidDueTomorrowTypeEnum {
	NotPaidDueTomorrow = 'NotPaid_DueTomorrow',
}

export interface CreditLineStateResponseWaitingStatement {
	/** @format date */
	dueDate: string;
	/** @format date */
	minPaymentCalculationDate: string;
	type: CreditLineStateResponseWaitingStatementTypeEnum;
}

export enum CreditLineStateResponseWaitingStatementTypeEnum {
	WaitingStatement = 'WaitingStatement',
}

export type CreditLinesDetailData = CreditLineResponse;

export type CreditLinesListData = CreditLineResponse[];

export type CreditLinesStateDetailData = CreditLineStateResponse;

export enum CurrencyEnum {
	PHP = 'PHP',
}

export enum CurrencyEnum1 {
	PHP = 'PHP',
}

export enum CurrencyEnum2 {
	PHP = 'PHP',
}

export interface DebitAccountInfoResponse {
	accountId: string;
	accountRequisites?: AccountRequisites;
	accountType: DebitAccountInfoResponseAccountTypeEnum;
	balance: Money;
	cards: CardResponse[];
	financialInstitution: FinancialInstitution;
	interest?: number;
	status: DebitAccountInfoResponseStatusEnum;
	tariffId: string;
}

export enum DebitAccountInfoResponseAccountTypeEnum {
	DEBIT = 'DEBIT',
}

export enum DebitAccountInfoResponseStatusEnum {
	ACTIVE = 'ACTIVE',
}

export interface DebitAccountResponse {
	accountId: string;
	accountRequisites: AccountRequisites;
	accountType: DebitAccountResponseAccountTypeEnum;
	balance: Money;
	cards?: CardResponse[];
	financialInstitution: FinancialInstitution;
	interest: number;
	status: DebitAccountResponseStatusEnum;
	tariffId: string;
}

export enum DebitAccountResponseAccountTypeEnum {
	DEBIT = 'DEBIT',
}

export enum DebitAccountResponseStatusEnum {
	ACTIVE = 'ACTIVE',
}

export interface DebitAccountV2Response {
	balance: Money;
	cards: CardResponse;
	financialInstitution: FinancialInstitution;
	id: string;
	interest: number;
	requisites: AccountRequisites;
	status: DebitAccountV2ResponseStatusEnum;
	tariffId: string;
	type: DebitAccountV2ResponseTypeEnum;
}

export enum DebitAccountV2ResponseStatusEnum {
	ACTIVE = 'ACTIVE',
}

export enum DebitAccountV2ResponseTypeEnum {
	DEBIT = 'DEBIT',
}

export type DebitDetailData = DebitAccountResponse;

export interface DisbursementProvider {
	code: string;
	constraints?: {
		accountsLengths?: number[];
	};
	/** @format int64 */
	fee: number;
	feeCurrency: string;
	id: string;
	name: string;
	type: DisbursementProviderTypeEnum;
}

export enum DisbursementProviderTypeEnum {
	E_WALLET = 'E_WALLET',
	BANK = 'BANK',
}

export interface ErrorResponse {
	errorDetails?: object;
	errorType: string;
	message: string;
}

export interface ErrorResponseInvalidOTP {
	errorDetails?: object;
	errorType?: ErrorResponseInvalidOtpErrorTypeEnum;
	/** @example "The provided one-time password is invalid" */
	message?: string;
}

export enum ErrorResponseInvalidOtpErrorTypeEnum {
	CommonConfirmationExceptionInvalidOTP = 'common.ConfirmationException.InvalidOTP',
}

export interface ErrorResponseTooManyAttempts {
	errorDetails?: object;
	errorType?: ErrorResponseTooManyAttemptsErrorTypeEnum;
	/** @example "Too many attempts" */
	message?: string;
}

export enum ErrorResponseTooManyAttemptsErrorTypeEnum {
	CommonConfirmationExceptionTooManyAttempts = 'common.ConfirmationException.TooManyAttempts',
}

export interface FeeExceedsRepaymentAmount {
	errorDetails?: {
		fee?: Money;
	};
	errorType?: FeeExceedsRepaymentAmountErrorTypeEnum;
	/** @example "The fee is greater than repayment amount" */
	message?: string;
}

export enum FeeExceedsRepaymentAmountErrorTypeEnum {
	PosapiRepaymentExceptionFeeExceedsRepaymentAmount = 'posapi.RepaymentException.FeeExceedsRepaymentAmount',
}

export enum FinancialInstitution {
	SUNPRIME = 'SUNPRIME',
	SALMON_BANK_INC = 'SALMON_BANK_INC',
}

export interface FinancingAccountV2Response {
	financialInstitution: FinancialInstitution;
	id: string;
	loanType: FinancingAccountV2ResponseLoanTypeEnum;
	progress: LoanProgress;
	state: LoanStateSchema;
	status: FinancingAccountV2ResponseStatusEnum;
	type: FinancingAccountV2ResponseTypeEnum;
}

export enum FinancingAccountV2ResponseLoanTypeEnum {
	CASH = 'CASH',
	POS = 'POS',
}

export enum FinancingAccountV2ResponseStatusEnum {
	ACTIVE = 'ACTIVE',
	OVERDUE = 'OVERDUE',
	CLOSED = 'CLOSED',
}

export enum FinancingAccountV2ResponseTypeEnum {
	FINANCING = 'FINANCING',
}

export interface FinancingPaymentsUpcomingResponse {
	/** @format date */
	dueDate: string;
	/** @format int64 */
	loanId: number;
	nextPayment: Money;
	requiredToPay: FinancingPaymentsUpcomingResponseRequiredToPayEnum;
	/**
	 * - `OVERDUE`: The payment is overdue.
	 *
	 * - `CURRENT`: The next payment is due either today or within the next 7 days.
	 *
	 * - `PENDING`: The next payment is due in more than 7 days.
	 */
	status: UpcomingPaymentStatus;
	type: FinancingPaymentsUpcomingResponseTypeEnum;
}

export enum FinancingPaymentsUpcomingResponseRequiredToPayEnum {
	//@ts-ignore
	True = true,
	//@ts-ignore
	False = false,
}

export enum FinancingPaymentsUpcomingResponseTypeEnum {
	FINANCING = 'FINANCING',
}

export interface GetAccountAgreementData {
	/** @example "Pre signed s3 file url" */
	location?: string;
}

export type GetAccountData =
	| FinancingAccountV2Response
	| CreditLineAccountV2Response
	| DebitAccountV2Response
	| SavingsAccountV2Response;

export interface GetAccountParams {
	accountId: string;
	accountType: AccountTypeEnum8;
}

export enum GetAccountParams1AccountTypeEnum {
	FINANCING = 'FINANCING',
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export interface GetAccountTariffData {
	/** @example "Pre signed s3 file url" */
	location?: string;
}

export type GetAccountsData = AccountsResponse;

export type GetAccountsInternalData = AccountsV2Response;

export type GetAccountsV2Data = AccountsV2Response;

export type GetAllPaymentsHistoryData = PaymentHistoryResponse;

export interface GetAllPaymentsHistoryParams {
	/** @format int32 */
	page: number;
	/** @format int32 */
	size: number;
}

export type GetApplicationData = ApplicationResponse[];

export type GetBafReferralData = BAFResponse;

export type GetCard2Data = CardRequisites;

export type GetCardData = CardResponse;

export type GetCardsData = CardsResponse;

export interface GetCardsParams {
	accountId?: string;
}

export type GetChallengesData = ChallengesResponse;

export type GetContractData = string[];

export type GetDisbursementProvidersData = DisbursementProvider[];

export type GetLoanData = LoanResponse;

export type GetOrderData = OrderResponse;

export type GetPaymentsHistoryData = PaymentHistoryResponse;

export interface GetPaymentsHistoryParams {
	loanId: string;
	/** @format int32 */
	page: number;
	/** @format int32 */
	size: number;
}

export interface GetPaymentsMethodsResponse {
	available: RepaymentProvider[];
	linked: RepaymentProvider[];
}

export type GetPaymentsScheduleData = PaymentScheduleResponse;

export type GetUserProfileData = UserProfileResponse;

export interface InsufficientFundsForCardIssuing {
	errorType?: InsufficientFundsForCardIssuingErrorTypeEnum;
	/** @example "Insufficient funds for card issuing" */
	message?: string;
}

export enum InsufficientFundsForCardIssuingErrorTypeEnum {
	PosapiCardExceptionInsufficientFundsForCardIssuing = 'posapi.CardException.InsufficientFundsForCardIssuing',
}

export type IssueCardData = CardResponse;

export interface IssueCardParams {
	accountId: string;
	accountType: AccountTypeEnum9;
}

export enum IssueCardParams1AccountTypeEnum {
	DEBIT = 'DEBIT',
}

export type LinkCardData = CardResponse;

export type LinkCardMmData = CardResponse;

export interface LinkCardMmParams {
	accountId: string;
	accountType: string;
}

export interface LinkedPaymentMethodRepayment {
	type: LinkedPaymentMethodRepaymentTypeEnum;
}

export enum LinkedPaymentMethodRepaymentTypeEnum {
	LINKED_PAYMENT_METHOD = 'LINKED_PAYMENT_METHOD',
}

/** @deprecated */
export interface LoanNextPayment {
	/** @format int64 */
	amount: number;
	/** @format int64 */
	base: number;
	/** @format int64 */
	debt: number;
	/** @format date */
	dueDate: string;
	/** @format int64 */
	fee: number;
	/** @format int64 */
	paid: number;
	upcoming?: Upcoming;
}

export interface LoanProgress {
	/** @format int32 */
	missed: number;
	/** @format int32 */
	paid: number;
	/** @format int32 */
	total: number;
}

export interface LoanResponse {
	financialInstitution: FinancialInstitution;
	/** @format int64 */
	loanId: number;
	loanNumber: string;
	loanType: LoanResponseLoanTypeEnum;
	nextPayment?: LoanNextPayment;
	progress: LoanProgress;
	state?: LoanStateSchema;
	status: LoanResponseStatusEnum;
}

export enum LoanResponseLoanTypeEnum {
	CASH = 'CASH',
	POS = 'POS',
}

export enum LoanResponseStatusEnum {
	ACTIVE = 'ACTIVE',
	OVERDUE = 'OVERDUE',
	CLOSED = 'CLOSED',
}

export interface LoanState {
	amount: Money;
	/** @format date */
	dueDate: string;
}

export type LoanStateSchema =
	| New
	| PartialPaid
	| PaidFull
	| PaidMore
	| NoMissedPayments
	| UpcomingNotPaid
	| UpcomingPartialPaid
	| Today
	| TodayPartialPaid
	| MissedPayment
	| MissedPaymentAndFee
	| MissedPaymentAndFeeUpcoming
	| MissedFee
	| MissedFeeUpcoming;

export interface LoanTerms {
	/** @format int64 */
	downPayment: number;
	/** @format int64 */
	loanAmount: number;
	/** @format int64 */
	loanTerms: number;
	/** @format int64 */
	monthlyPayment: number;
}

export type MissedFee = LoanState & {
	type: MissedFeeTypeEnum;
};

export enum MissedFeeTypeEnum {
	MissedPayment = 'MissedPayment',
}

export type MissedFeeUpcoming = LoanState & {
	type: MissedFeeUpcomingTypeEnum;
	upcoming: Money;
	/** @format date */
	upcomingDueDate: string;
};

export enum MissedFeeUpcomingTypeEnum {
	MissedFeeUpcoming = 'MissedFeeUpcoming',
}

export type MissedPayment = LoanState & {
	type: MissedPaymentTypeEnum;
};

export type MissedPaymentAndFee = LoanState & {
	debt: Money;
	fee: Money;
	type: MissedPaymentAndFeeTypeEnum;
};

export enum MissedPaymentAndFeeTypeEnum {
	MissedPaymentAndFee = 'MissedPaymentAndFee',
}

export type MissedPaymentAndFeeUpcoming = LoanState & {
	debt: Money;
	fee: Money;
	type: MissedPaymentAndFeeUpcomingTypeEnum;
	upcoming: Money;
	/** @format date */
	upcomingDueDate: string;
};

export enum MissedPaymentAndFeeUpcomingTypeEnum {
	MissedPaymentAndFeeUpcoming = 'MissedPaymentAndFeeUpcoming',
}

export enum MissedPaymentTypeEnum {
	MissedPayment = 'MissedPayment',
}

export interface Money {
	/** @example 1500.75 */
	amount: number;
	currency: MoneyCurrencyEnum;
}

export enum MoneyCurrencyEnum {
	PHP = 'PHP',
}

export interface NeedConfirmationError {
	errorDetails?: {
		confirmationTicketStartResponse?: ConfirmationTicketStartResponse;
	};
	errorType?: NeedConfirmationErrorErrorTypeEnum;
	/** @example "Operation needs confirmation" */
	message?: string;
}

export enum NeedConfirmationErrorErrorTypeEnum {
	ConfirmationConfirmationExceptionNeedConfirmationPhone = 'confirmation.ConfirmationException.NeedConfirmationPhone',
}

export type New = LoanState & {
	type: NewTypeEnum;
};

export interface NewApplicationRequest {
	/** @format int64 */
	amount: number;
	/** @format int32 */
	months: number;
	providerAccount: string;
	providerId: string;
	providerType: NewApplicationRequestProviderTypeEnum;
}

export enum NewApplicationRequestProviderTypeEnum {
	E_WALLET = 'E_WALLET',
	BANK = 'BANK',
}

export interface NewApplicationResponse {
	applicationNumber: string;
	contractUrl: string;
}

export enum NewTypeEnum {
	New = 'New',
}

export type NoMissedPayments = LoanState & {
	type: NoMissedPaymentsTypeEnum;
};

export enum NoMissedPaymentsTypeEnum {
	NoMissedPayments = 'NoMissedPayments',
}

export type NoOutstandingBalance = ErrorResponse & {
	errorType?: NoOutstandingBalanceErrorTypeEnum;
	/** @example "No outstanding balance" */
	message?: string;
};

export enum NoOutstandingBalanceErrorTypeEnum {
	PosapiCreditLineExceptionRepaymentNoOutstandingBalance = 'posapi.CreditLineException.Repayment.NoOutstandingBalance',
}

export interface Operation {
	account: {
		accountId?: string;
		type: OperationTypeEnum;
	};
	amount: Money;
	brand: {
		color?: {
			/** @example "#c0c0c0" */
			baseBackground?: string;
			/** @example "#c0c0c0" */
			baseText?: string;
		};
		/** @example "https://s.slmn.ph/payment/providers/gcash.png" */
		logo: string;
		name: string;
	};
	/** @format date */
	date: string;
	details: OperationDetails;
	direction: OperationDirectionEnum;
	fee?: Money;
	identifier: OperationIdentifier;
	status: OperationStatusEnum;
	/** @example "Kristine dela Cruz" */
	to: string;
	total: Money;
	type: OperationTypeEnum1;
}

export type OperationDetails =
	| OperationDetailsRepayment
	| OperationDetailsTransfer
	| OperationDetailsQrPayment;

export interface OperationDetailsQrPayment {
	channelName: string;
	merchant: string;
	/** @example "Instapay" */
	transferServiceName: string;
	/** @example 123456 */
	transferServiceReferenceNumber?: string;
	type: OperationDetailsQrPaymentTypeEnum;
}

export enum OperationDetailsQrPaymentTypeEnum {
	QR_PAYMENT = 'QR_PAYMENT',
}

export interface OperationDetailsRepayment {
	provider: OperationProvider;
	type: OperationDetailsRepaymentTypeEnum;
}

export enum OperationDetailsRepaymentTypeEnum {
	REPAYMENT = 'REPAYMENT',
}

export interface OperationDetailsTransfer {
	accountName: string;
	accountNumber: string;
	provider: OperationProvider;
	/** @example "Instapay" */
	transferServiceName?: string;
	/** @example 123456 */
	transferServiceReferenceNumber?: string;
	type: OperationDetailsTransferTypeEnum;
}

export enum OperationDetailsTransferTypeEnum {
	TRANSFER = 'TRANSFER',
}

export enum OperationDirectionEnum {
	IN = 'IN',
	OUT = 'OUT',
}

export interface OperationIdentifier {
	id: string;
	system: OperationIdentifierSystemEnum;
}

export enum OperationIdentifierSystemEnum {
	PG = 'PG',
	IF = 'IF',
}

export interface OperationProvider {
	code: string;
	fee?: Money;
	id: string;
	name: string;
}

export interface OperationResponse {
	operation: Operation;
}

export enum OperationStatusEnum {
	SUCCESSFUL = 'SUCCESSFUL',
	PENDING = 'PENDING',
	FAILED = 'FAILED',
}

export enum OperationTypeEnum {
	FINANCING = 'FINANCING',
	CREDIT_LINE = 'CREDIT_LINE',
}

export enum OperationTypeEnum1 {
	REPAYMENT = 'REPAYMENT',
	TRANSFER = 'TRANSFER',
	QR_PAYMENT = 'QR_PAYMENT',
	CARD_PAYMENT = 'CARD_PAYMENT',
	BILL_PAYMENT = 'BILL_PAYMENT',
	BANK_SERVICE = 'BANK_SERVICE',
	OTHER = 'OTHER',
}

export type OperationsDetailData = OperationResponse;

export interface OperationsDetailParams {
	operationId: string;
	system: SystemEnum;
}

export enum OperationsDetailParams1SystemEnum {
	PG = 'PG',
	IF = 'IF',
}

export type OperationsListData = OperationsResponse;

export interface OperationsListParams {
	accountId?: string;
	accountType?: AccountTypeEnum10;
	/** @format int32 */
	page: number;
	/** @format int32 */
	size: number;
}

export enum OperationsListParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
}

export interface OperationsResponse {
	operations: Operation[];
}

export interface OrderItem {
	name: string;
	/** @format int64 */
	price: number;
	/** @format int64 */
	quantity: number;
	/** @format int64 */
	total: number;
}

export interface OrderResponse {
	basket?: Basket;
	items: OrderItem[];
	loanTerms: LoanTerms;
	storeAddress: string;
	storeName: string;
	/** @format int64 */
	total?: number;
}

export type PaidFull = LoanState & {
	type: PaidFullTypeEnum;
};

export enum PaidFullTypeEnum {
	PaidFull = 'PaidFull',
}

export type PaidMore = LoanState & {
	overpaid: Money;
	type?: PaidMoreTypeEnum;
};

export enum PaidMoreTypeEnum {
	PaidMore = 'PaidMore',
}

export type PartialPaid = LoanState & {
	base: Money;
	paid: Money;
	type: PartialPaidTypeEnum;
};

export enum PartialPaidTypeEnum {
	PartialPaid = 'PartialPaid',
}

export interface PatchPaymentsMethodsRequest {
	/** Enable/Disable autopayments for this payment method */
	autoPayment: boolean;
}

export type PaymentAmountIsTooBig = ErrorResponse & {
	errorDetails?: {
		outstanding?: Money;
		paymentAmount?: Money;
	};
	errorType?: PaymentAmountIsTooBigErrorTypeEnum;
	/** @example "Payment amount is greater than outstanding balance: outstanding=1000 paymentAmount=1201.0" */
	message?: string;
};

export enum PaymentAmountIsTooBigErrorTypeEnum {
	PosapiCreditLineExceptionRepaymentPaymentAmountIsTooBig = 'posapi.CreditLineException.Repayment.PaymentAmountIsTooBig',
}

export interface PaymentHistoryEntry {
	/** @format int64 */
	amount: number;
	/** @format date-time */
	date: string;
	provider: PaymentProviderSimple;
	status: PaymentHistoryEntryStatusEnum;
}

export enum PaymentHistoryEntryStatusEnum {
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

export interface PaymentHistoryResponse {
	history: PaymentHistoryEntry[];
}

export interface PaymentMethodAlreadyLinked {
	errorType?: PaymentMethodAlreadyLinkedErrorTypeEnum;
	/** @example "Account is already linked" */
	message?: string;
}

export enum PaymentMethodAlreadyLinkedErrorTypeEnum {
	PosapiPaymentsMethodsExceptionAccountAlreadyLinked = 'posapi.PaymentsMethodsException.AccountAlreadyLinked',
}

export interface PaymentOptionResponse {
	providers: PaymentProvider[];
	type: string;
}

export type PaymentOptionsListData = PaymentOptionResponse[];

export interface PaymentOptionsListParams {
	/** @format int64 */
	paymentAmount?: number;
}

export interface PaymentProvider {
	code: string;
	/** @format int64 */
	fee: number;
	id: string;
	/** @format int64 */
	maxAmount: number;
	/** @format int64 */
	minAmount: number;
	name: string;
}

export interface PaymentProviderAccountLengthConstraint {
	lengths?: number[];
	type?: PaymentProviderAccountLengthConstraintTypeEnum;
}

export enum PaymentProviderAccountLengthConstraintTypeEnum {
	ACCOUNT_LENGTH_CONSTRAINT = 'ACCOUNT_LENGTH_CONSTRAINT',
}

export interface PaymentProviderAmountConstraint {
	max?: Money;
	min?: Money;
	type?: PaymentProviderAmountConstraintTypeEnum;
}

export enum PaymentProviderAmountConstraintTypeEnum {
	AMOUNT_CONSTRAINT = 'AMOUNT_CONSTRAINT',
}

export type PaymentProviderCreateData = PerformPaymentResponse;

export interface PaymentProviderSimple {
	code: string;
	/** @format int64 */
	fee: number;
	id: string;
	name: string;
}

export interface PaymentRequest {
	/** @format int64 */
	loanId: number;
	/** @format int64 */
	paymentAmount: number;
}

export interface PaymentScheduleEntry {
	/** @format int64 */
	base: number;
	/** @format date */
	dueDate: string;
	/** @format int64 */
	fee: number;
	/** @format int64 */
	paid: number;
	status: PaymentScheduleEntryStatusEnum;
}

export enum PaymentScheduleEntryStatusEnum {
	PAID = 'PAID',
	MISSED = 'MISSED',
	PENDING = 'PENDING',
	CURRENT = 'CURRENT',
}

export interface PaymentScheduleResponse {
	/** @format int64 */
	leftToPay: number;
	schedule: PaymentScheduleEntry[];
}

export type PaymentsMethodsCreateData = PostPaymentsMethodsResponse;

export type PaymentsMethodsDeleteData = any;

export type PaymentsMethodsListData = GetPaymentsMethodsResponse;

export type PaymentsMethodsPartialUpdateData = any;

export type PaymentsQrCreateData = QrResponse;

export type PaymentsQrFeeDetailData = QrPaymentFeeResponse;

export interface PaymentsQrFeeDetailParams {
	accountId: string;
	accountType: AccountTypeEnum3;
	/** @example 1500.75 */
	amount: number;
	currency: CurrencyEnum1;
	qrId: string;
}

export enum PaymentsQrFeeDetailParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
}

export enum PaymentsQrFeeDetailParams1CurrencyEnum {
	PHP = 'PHP',
}

export type PaymentsQrPaymentCreateData = QrPaymentResponse;

export type PaymentsRepaymentsCreateData = PerformRepaymentResponse;

export type PaymentsRepaymentsProvidersFeeDetailData = ProviderFee;

export interface PaymentsRepaymentsProvidersFeeDetailParams {
	accountId: string;
	accountType: AccountTypeEnum6;
	amount: string;
	currency: CurrencyEnum2;
	providerId: string;
}

export enum PaymentsRepaymentsProvidersFeeDetailParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum PaymentsRepaymentsProvidersFeeDetailParams1CurrencyEnum {
	PHP = 'PHP',
}

export interface PaymentsRepaymentsProvidersListData {
	providers?: {
		/** @example "E-wallet" */
		group: string;
		providers: RepaymentProvider[];
		/**
		 * Use group instead
		 * @deprecated
		 * @example "E-wallet"
		 */
		type?: string;
	}[];
}

export interface PaymentsRepaymentsProvidersListParams {
	accountId: string;
	accountType: AccountTypeEnum4;
	/** @default true */
	showLinked?: boolean;
}

export enum PaymentsRepaymentsProvidersListParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export interface PaymentsRepaymentsProvidersSortedListData {
	others: RepaymentProvider[];
	popular: RepaymentProvider[];
}

export interface PaymentsRepaymentsProvidersSortedListParams {
	accountId: string;
	accountType: AccountTypeEnum5;
	/** @default true */
	showLinked?: boolean;
}

export enum PaymentsRepaymentsProvidersSortedListParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export interface PaymentsRulesAccount {
	accountId: string;
	accountType: PaymentsRulesAccountType;
}

export enum PaymentsRulesAccountType {
	DEBIT = 'DEBIT',
	CREDIT_LINE = 'CREDIT_LINE',
	SAVINGS = 'SAVINGS',
	FINANCING = 'FINANCING',
}

export type PaymentsRulesListData = PaymentsRulesResponse;

export interface PaymentsRulesResponse {
	rules: RulesResponse;
}

export type PaymentsTransfersCreateData = TransferResponse;

export type PaymentsTransfersLimitsCheckCreateData = any;

export type PaymentsTransfersProvidersFeeDetailData = ProviderFee;

export interface PaymentsTransfersProvidersFeeDetailParams {
	accountId: string;
	accountType: AccountTypeEnum2;
	amount: string;
	currency: CurrencyEnum;
	providerId: string;
}

export enum PaymentsTransfersProvidersFeeDetailParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export enum PaymentsTransfersProvidersFeeDetailParams1CurrencyEnum {
	PHP = 'PHP',
}

export interface PaymentsTransfersProvidersListData {
	providers?: TransferProvider[];
}

export interface PaymentsTransfersProvidersListParams {
	accountType: AccountTypeEnum;
}

export enum PaymentsTransfersProvidersListParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export interface PaymentsTransfersProvidersSortedListData {
	others: TransferProvider[];
	popular: TransferProvider[];
}

export interface PaymentsTransfersProvidersSortedListParams {
	accountType: AccountTypeEnum1;
}

export enum PaymentsTransfersProvidersSortedListParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export type PaymentsUpcomingListData = PaymentsUpcomingResponse;

export interface PaymentsUpcomingListParams {
	accountType: AccountTypeEnum7;
}

export enum PaymentsUpcomingListParams1AccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
}

export type PaymentsUpcomingResponse =
	| FinancingPaymentsUpcomingResponse[]
	| CreditLinePaymentsUpcomingResponse[];

export interface PerformPaymentResponse {
	location: string;
}

export interface PerformRepaymentResponse {
	identifier: OperationIdentifier;
	/** @deprecated */
	location?: string;
	paymentData: URLRepayment | QRRepayment | LinkedPaymentMethodRepayment;
}

export interface PostPaymentsMethodsRequests {
	providerCode: string;
	redirectSource?: PostPaymentsMethodsRequestsRedirectSourceEnum;
}

export enum PostPaymentsMethodsRequestsRedirectSourceEnum {
	AutoDebit = 'AutoDebit',
	PaymentSheet = 'PaymentSheet',
}

export interface PostPaymentsMethodsResponse {
	linkUrl: string;
}

export interface ProviderFee {
	amount?: Money;
	fee: Money;
	total?: Money;
}

export interface QRPaymentAccount {
	accountId: string;
	accountType: QrPaymentAccountAccountTypeEnum;
}

export interface QRRepayment {
	/** @format date-time */
	expirationTime?: string;
	qrUrl: string;
	type: QrRepaymentTypeEnum;
}

export type QrNotSupported = ErrorResponse & {
	errorType?: QrNotSupportedErrorTypeEnum;
	/** @example "P2P qr payments are not allowed" */
	message?: string;
};

export enum QrNotSupportedErrorTypeEnum {
	PosapiQrExceptionP2PNotAllowed = 'posapi.QrException.P2PNotAllowed',
	PosapiQrExceptionUnknownQR = 'posapi.QrException.UnknownQR',
	PosapiQrExceptionPolicyCheckFailed = 'posapi.QrException.PolicyCheckFailed',
}

export enum QrPaymentAccountAccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
}

export interface QrPaymentChannel {
	code: string;
	name: string;
}

export interface QrPaymentFeeResponse {
	amount?: Money;
	fee: Money;
	total?: Money;
}

export interface QrPaymentMerchant {
	location: string;
	name: string;
}

export interface QrPaymentRequest {
	amount: Money;
	fee: Money;
	from: QRPaymentAccount;
}

export interface QrPaymentResponse {
	identifier: OperationIdentifier;
}

export enum QrRepaymentTypeEnum {
	QR = 'QR',
}

export interface QrRequest {
	from: QRPaymentAccount;
	/** @example "Some qr code string representation" */
	qrString: string;
}

export interface QrResponse {
	amount?: Money;
	channel: QrPaymentChannel;
	fee?: Money;
	merchant: QrPaymentMerchant;
	qrId: string;
	qrType: QrResponseQrTypeEnum;
	total?: Money;
}

export enum QrResponseQrTypeEnum {
	P2P = 'P2P',
	P2M = 'P2M',
}

export interface RepaymentPaymentAccount {
	accountId: string;
	accountType: RepaymentPaymentAccountAccountTypeEnum;
}

export enum RepaymentPaymentAccountAccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export interface RepaymentProvider {
	code: string;
	constraints: PaymentProviderAmountConstraint[];
	fee?: Money;
	id: string;
	labels?: RepaymentProviderLabelsEnum[];
	/** The provider is available to link as a payment method */
	linkAvailable?: boolean;
	linkInstructionType?: RepaymentProviderLinkInstructionTypeEnum;
	linkedPaymentMethod?: {
		/**
		 * A partially masked E-wallet or Bank account number
		 * @example "+63 *** *** 11 22"
		 */
		accountNumber: string;
		/** Auto payments are enabled or disabled for the account. */
		autoPayment: boolean;
		id: string;
	};
	logo?: string;
	name: string;
}

export enum RepaymentProviderLabelsEnum {
	Linked = 'Linked',
}

export enum RepaymentProviderLinkInstructionTypeEnum {
	BANK = 'BANK',
}

export interface RepaymentRequest {
	amount: Money;
	from: RepaymentPaymentAccount;
	linkedPaymentMethodId?: string;
	providerId: string;
}

export type RequestedResourceNotFound = ErrorResponse & {
	errorType?: RequestedResourceNotFoundErrorTypeEnum;
	/** @example "Requested resource not found" */
	message?: string;
};

export enum RequestedResourceNotFoundErrorTypeEnum {
	ExceptionsDefault = 'exceptions.default',
}

export type RulesResponse = {
	accountId: string;
	accountType: PaymentsRulesAccountType;
	paymentFrom: PaymentsRulesAccount[];
	paymentTo: PaymentsRulesAccount[];
	qrAvailable: boolean;
	topUpProvidersAvailable: boolean;
	transferProvidersAvailable: boolean;
}[];

export interface SavingsAccountInfoResponse {
	accountId: string;
	accountRequisites?: AccountRequisites;
	accountType: SavingsAccountInfoResponseAccountTypeEnum;
	balance: Money;
	financialInstitution: FinancialInstitution;
	interest?: number;
	status: SavingsAccountInfoResponseStatusEnum;
	tariffId: string;
}

export enum SavingsAccountInfoResponseAccountTypeEnum {
	SAVINGS = 'SAVINGS',
}

export enum SavingsAccountInfoResponseStatusEnum {
	ACTIVE = 'ACTIVE',
}

export interface SavingsAccountResponse {
	accountId: string;
	accountRequisites: AccountRequisites;
	accountType: SavingsAccountResponseAccountTypeEnum;
	balance: Money;
	financialInstitution: FinancialInstitution;
	interest: string;
	status: SavingsAccountResponseStatusEnum;
	tariffId: string;
}

export enum SavingsAccountResponseAccountTypeEnum {
	SAVINGS = 'SAVINGS',
}

export enum SavingsAccountResponseStatusEnum {
	ACTIVE = 'ACTIVE',
}

export interface SavingsAccountV2Response {
	balance: Money;
	financialInstitution: FinancialInstitution;
	id: string;
	interest: number;
	requisites: AccountRequisites;
	status: SavingsAccountV2ResponseStatusEnum;
	tariffId: string;
	type: SavingsAccountV2ResponseTypeEnum;
}

export enum SavingsAccountV2ResponseStatusEnum {
	ACTIVE = 'ACTIVE',
}

export enum SavingsAccountV2ResponseTypeEnum {
	SAVINGS = 'SAVINGS',
}

export type SavingsDetailData = SavingsAccountResponse;

export type SetPin2Data = object;

export type SetPin3Data = object;

export type SetPinData = object;

export interface SetPinRequest {
	pin: string;
}

export interface StartCashConfirmRequest {
	applicationNumber: string;
	confirmationChannel: StartCashConfirmRequestConfirmationChannelEnum;
}

export enum StartCashConfirmRequestConfirmationChannelEnum {
	SMS = 'SMS',
	VIBER = 'VIBER',
	VOICE = 'VOICE',
}

export interface StartCashConfirmResponse {
	confirmationId: string;
}

export type StartCashConfirmationsData = StartCashConfirmResponse;

export enum SystemEnum {
	PG = 'PG',
	IF = 'IF',
}

export type Today = LoanState & {
	type: TodayTypeEnum;
};

export type TodayPartialPaid = LoanState & {
	base: Money;
	paid: Money;
	type: TodayPartialPaidTypeEnum;
};

export enum TodayPartialPaidTypeEnum {
	TodayPartialPaid = 'TodayPartialPaid',
}

export enum TodayTypeEnum {
	Today = 'Today',
}

export type TransferException = ErrorResponse & {
	errorType?: TransferExceptionErrorTypeEnum;
	/** @example "The daily limit is exceeded" */
	message?: string;
};

export enum TransferExceptionErrorTypeEnum {
	PosapiTransferDailyLimitError = 'posapi.Transfer.DailyLimitError',
	PosapiTransferMonthlyLimitError = 'posapi.Transfer.MonthlyLimitError',
	PosapiTransferBeneficiaryAccountError = 'posapi.Transfer.BeneficiaryAccountError',
	PosapiTransferPolicyCheckFailed = 'posapi.Transfer.PolicyCheckFailed',
}

export interface TransferPaymentAccount {
	accountId: string;
	accountType: TransferPaymentAccountAccountTypeEnum;
}

export enum TransferPaymentAccountAccountTypeEnum {
	CREDIT_LINE = 'CREDIT_LINE',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export interface TransferProvider {
	code: string;
	constraints: (
		| PaymentProviderAccountLengthConstraint
		| PaymentProviderAmountConstraint
	)[];
	fee: Money;
	id: string;
	logo?: string;
	name: string;
	type: TransferProviderTypeEnum;
}

export enum TransferProviderTypeEnum {
	E_WALLET = 'E_WALLET',
	BANK = 'BANK',
}

export interface TransferRequest {
	amount: Money;
	from: TransferPaymentAccount;
	to: {
		accountName: string;
		accountNumber: string;
		providerId: string;
	};
}

export interface TransferResponse {
	identifier: OperationIdentifier;
}

export interface TransfersLimitExceeded {
	errorDetails: TransfersLimitExceededInfo | AvailableBalanceLimitExceededInfo;
	errorType: TransfersLimitExceededErrorTypeEnum;
	/** @example "Limit exceeded" */
	message: string;
}

export enum TransfersLimitExceededErrorTypeEnum {
	PosapiTransferLimitExceeded = 'posapi.Transfer.LimitExceeded',
}

export interface TransfersLimitExceededInfo {
	limitType: TransfersLimitExceededInfoLimitTypeEnum;
	maxAmount: Money;
}

export enum TransfersLimitExceededInfoLimitTypeEnum {
	Daily = 'Daily',
	Monthly = 'Monthly',
	PerTransaction = 'PerTransaction',
}

export interface URLRepayment {
	paymentUrl: string;
	type: UrlRepaymentTypeEnum;
}

export interface Upcoming {
	/** @format int64 */
	amount: number;
	/** @format date */
	dueDate: string;
}

export type UpcomingNotPaid = LoanState & {
	/** @format int64 */
	daysBeforeDueDate: number;
	type: UpcomingNotPaidTypeEnum;
};

export enum UpcomingNotPaidTypeEnum {
	UpcomingNotPaid = 'UpcomingNotPaid',
}

export type UpcomingPartialPaid = LoanState & {
	base: Money;
	/** @format int64 */
	daysBeforeDueDate: number;
	paid: Money;
	type: UpcomingPartialPaidTypeEnum;
};

export enum UpcomingPartialPaidTypeEnum {
	UpcomingPartialPaid = 'UpcomingPartialPaid',
}

/**
 * - `OVERDUE`: The payment is overdue.
 *
 * - `CURRENT`: The next payment is due either today or within the next 7 days.
 *
 * - `PENDING`: The next payment is due in more than 7 days.
 */
export enum UpcomingPaymentStatus {
	OVERDUE = 'OVERDUE',
	CURRENT = 'CURRENT',
	PENDING = 'PENDING',
}

export enum UrlRepaymentTypeEnum {
	URL = 'URL',
}

export interface UserProfileResponse {
	email: string;
	employee: boolean;
	firstName: string;
	fullName: string;
	newProfile?: boolean;
	phone: string;
	referralCode?: string;
}

export interface Warnings {
	/** This field indicates whether some backends failed, which could result in accounts being missing from the response */
	incompleteAccounts?: boolean;
	/** This field indicates whether some backends failed, which could result in cards being missing from the response */
	incompleteCards?: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<
	FullRequestParams,
	'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
	extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = 'https://pos-api.loc.uat.fhl.world';
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
		fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: 'same-origin',
		headers: {},
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(
			typeof value === 'number' ? value : `${value}`
		)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter(
			(key) => 'undefined' !== typeof query[key]
		);
		return keys
			.map((key) =>
				Array.isArray(query[key])
					? this.addArrayQueryParam(query, key)
					: this.addQueryParam(query, key)
			)
			.join('&');
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : '';
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null && (typeof input === 'object' || typeof input === 'string')
				? JSON.stringify(input)
				: input,
		[ContentType.Text]: (input: any) =>
			input !== null && typeof input !== 'string'
				? JSON.stringify(input)
				: input,
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob
						? property
						: typeof property === 'object' && property !== null
						? JSON.stringify(property)
						: `${property}`
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(
		params1: RequestParams,
		params2?: RequestParams
	): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (
		cancelToken: CancelToken
	): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(
			`${baseUrl || this.baseUrl || ''}${path}${
				queryString ? `?${queryString}` : ''
			}`,
			{
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData
						? { 'Content-Type': type }
						: {}),
				},
				signal:
					(cancelToken
						? this.createAbortSignal(cancelToken)
						: requestParams.signal) || null,
				body:
					typeof body === 'undefined' || body === null
						? null
						: payloadFormatter(body),
			}
		).then(async (response) => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then((data) => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch((e) => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title API
 * @version 1.0.0
 * @baseUrl https://pos-api.loc.uat.fhl.world
 */
export class Api<
	SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
	v1 = {
		/**
		 * No description
		 *
		 * @tags transfers
		 * @name PaymentsTransfersProvidersList
		 * @request GET:/v1/payments/transfers/providers
		 */
		paymentsTransfersProvidersList: (
			query: PaymentsTransfersProvidersListParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentsTransfersProvidersListData, any>({
				path: `/v1/payments/transfers/providers`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags transfers
		 * @name PaymentsTransfersProvidersSortedList
		 * @request GET:/v1/payments/transfers/providers/sorted
		 */
		paymentsTransfersProvidersSortedList: (
			query: PaymentsTransfersProvidersSortedListParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentsTransfersProvidersSortedListData, any>({
				path: `/v1/payments/transfers/providers/sorted`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags transfers
		 * @name PaymentsTransfersProvidersFeeDetail
		 * @request GET:/v1/payments/transfers/providers/{providerId}/fee
		 */
		paymentsTransfersProvidersFeeDetail: (
			{ providerId, ...query }: PaymentsTransfersProvidersFeeDetailParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentsTransfersProvidersFeeDetailData, any>({
				path: `/v1/payments/transfers/providers/${providerId}/fee`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags transfers
		 * @name PaymentsTransfersLimitsCheckCreate
		 * @request POST:/v1/payments/transfers/limits/check
		 */
		paymentsTransfersLimitsCheckCreate: (
			data: TransferRequest,
			params: RequestParams = {}
		) =>
			this.request<
				PaymentsTransfersLimitsCheckCreateData,
				TransfersLimitExceeded
			>({
				path: `/v1/payments/transfers/limits/check`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags transfers
		 * @name PaymentsTransfersCreate
		 * @request POST:/v1/payments/transfers
		 */
		paymentsTransfersCreate: (
			data: TransferRequest,
			params: RequestParams = {}
		) =>
			this.request<
				PaymentsTransfersCreateData,
				TransferException | NeedConfirmationError
			>({
				path: `/v1/payments/transfers`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags qr
		 * @name PaymentsQrCreate
		 * @request POST:/v1/payments/qr
		 */
		paymentsQrCreate: (data: QrRequest, params: RequestParams = {}) =>
			this.request<PaymentsQrCreateData, QrNotSupported>({
				path: `/v1/payments/qr`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags qr
		 * @name PaymentsQrPaymentCreate
		 * @request POST:/v1/payments/qr/{qrId}/payment
		 */
		paymentsQrPaymentCreate: (
			qrId: string,
			data: QrPaymentRequest,
			params: RequestParams = {}
		) =>
			this.request<PaymentsQrPaymentCreateData, any>({
				path: `/v1/payments/qr/${qrId}/payment`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags qr
		 * @name PaymentsQrFeeDetail
		 * @request GET:/v1/payments/qr/{qrId}/fee
		 */
		paymentsQrFeeDetail: (
			{ qrId, ...query }: PaymentsQrFeeDetailParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentsQrFeeDetailData, any>({
				path: `/v1/payments/qr/${qrId}/fee`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags repayments
		 * @name PaymentsRepaymentsProvidersList
		 * @request GET:/v1/payments/repayments/providers
		 */
		paymentsRepaymentsProvidersList: (
			query: PaymentsRepaymentsProvidersListParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentsRepaymentsProvidersListData, any>({
				path: `/v1/payments/repayments/providers`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags repayments
		 * @name PaymentsRepaymentsProvidersSortedList
		 * @request GET:/v1/payments/repayments/providers/sorted
		 */
		paymentsRepaymentsProvidersSortedList: (
			query: PaymentsRepaymentsProvidersSortedListParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentsRepaymentsProvidersSortedListData, any>({
				path: `/v1/payments/repayments/providers/sorted`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags repayments
		 * @name PaymentsRepaymentsProvidersFeeDetail
		 * @request GET:/v1/payments/repayments/providers/{providerId}/fee
		 */
		paymentsRepaymentsProvidersFeeDetail: (
			{ providerId, ...query }: PaymentsRepaymentsProvidersFeeDetailParams,
			params: RequestParams = {}
		) =>
			this.request<
				PaymentsRepaymentsProvidersFeeDetailData,
				FeeExceedsRepaymentAmount
			>({
				path: `/v1/payments/repayments/providers/${providerId}/fee`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags repayments
		 * @name PaymentsRepaymentsCreate
		 * @request POST:/v1/payments/repayments
		 */
		paymentsRepaymentsCreate: (
			data: RepaymentRequest,
			params: RequestParams = {}
		) =>
			this.request<
				PaymentsRepaymentsCreateData,
				NoOutstandingBalance | PaymentAmountIsTooBig
			>({
				path: `/v1/payments/repayments`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payments
		 * @name PaymentsUpcomingList
		 * @request GET:/v1/payments/upcoming
		 */
		paymentsUpcomingList: (
			query: PaymentsUpcomingListParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentsUpcomingListData, any>({
				path: `/v1/payments/upcoming`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payments
		 * @name PaymentsRulesList
		 * @summary Get all payments rules of client
		 * @request GET:/v1/payments/rules
		 */
		paymentsRulesList: (params: RequestParams = {}) =>
			this.request<PaymentsRulesListData, any>({
				path: `/v1/payments/rules`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payments methods
		 * @name PaymentsMethodsList
		 * @request GET:/v1/payments/methods
		 */
		paymentsMethodsList: (params: RequestParams = {}) =>
			this.request<PaymentsMethodsListData, any>({
				path: `/v1/payments/methods`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payments methods
		 * @name PaymentsMethodsCreate
		 * @request POST:/v1/payments/methods
		 */
		paymentsMethodsCreate: (
			data: PostPaymentsMethodsRequests,
			params: RequestParams = {}
		) =>
			this.request<PaymentsMethodsCreateData, PaymentMethodAlreadyLinked>({
				path: `/v1/payments/methods`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payments methods
		 * @name PaymentsMethodsPartialUpdate
		 * @request PATCH:/v1/payments/methods/{paymentMethodId}
		 */
		paymentsMethodsPartialUpdate: (
			paymentMethodId: string,
			data: PatchPaymentsMethodsRequest,
			params: RequestParams = {}
		) =>
			this.request<PaymentsMethodsPartialUpdateData, any>({
				path: `/v1/payments/methods/${paymentMethodId}`,
				method: 'PATCH',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payments methods
		 * @name PaymentsMethodsDelete
		 * @request DELETE:/v1/payments/methods/{paymentMethodId}
		 */
		paymentsMethodsDelete: (
			paymentMethodId: string,
			params: RequestParams = {}
		) =>
			this.request<PaymentsMethodsDeleteData, any>({
				path: `/v1/payments/methods/${paymentMethodId}`,
				method: 'DELETE',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payment
		 * @name PaymentOptionsList
		 * @request GET:/v1/payment/options
		 * @deprecated
		 */
		paymentOptionsList: (
			query: PaymentOptionsListParams,
			params: RequestParams = {}
		) =>
			this.request<PaymentOptionsListData, any>({
				path: `/v1/payment/options`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags payment
		 * @name PaymentProviderCreate
		 * @request POST:/v1/payment/provider/{providerId}
		 * @deprecated
		 */
		paymentProviderCreate: (
			providerId: string,
			data: PaymentRequest,
			params: RequestParams = {}
		) =>
			this.request<PaymentProviderCreateData, any>({
				path: `/v1/payment/provider/${providerId}`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name GetUserProfile
		 * @request GET:/v1/user/profile
		 */
		getUserProfile: (params: RequestParams = {}) =>
			this.request<GetUserProfileData, any>({
				path: `/v1/user/profile`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags accounts
		 * @name GetAccounts
		 * @request GET:/v1/accounts
		 * @deprecated
		 */
		getAccounts: (params: RequestParams = {}) =>
			this.request<GetAccountsData, any>({
				path: `/v1/accounts`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags accounts
		 * @name GetAccount
		 * @request GET:/v1/accounts/{accountId}
		 */
		getAccount: (
			{ accountId, ...query }: GetAccountParams,
			params: RequestParams = {}
		) =>
			this.request<GetAccountData, RequestedResourceNotFound>({
				path: `/v1/accounts/${accountId}`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags accounts
		 * @name GetAccountAgreement
		 * @request GET:/v1/accounts/{accountId}/agreement
		 */
		getAccountAgreement: (accountId: string, params: RequestParams = {}) =>
			this.request<
				GetAccountAgreementData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/accounts/${accountId}/agreement`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags accounts
		 * @name GetAccountTariff
		 * @request GET:/v1/accounts/{accountId}/tariff
		 */
		getAccountTariff: (accountId: string, params: RequestParams = {}) =>
			this.request<
				GetAccountTariffData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/accounts/${accountId}/tariff`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Use /v1/accounts/{accountId} instead
		 *
		 * @tags debit
		 * @name DebitDetail
		 * @request GET:/v1/debit/{accountId}
		 * @deprecated
		 */
		debitDetail: (accountId: string, params: RequestParams = {}) =>
			this.request<DebitDetailData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/debit/${accountId}`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Use /v1/accounts/{accountId} instead
		 *
		 * @tags savings
		 * @name SavingsDetail
		 * @request GET:/v1/savings/{accountId}
		 * @deprecated
		 */
		savingsDetail: (accountId: string, params: RequestParams = {}) =>
			this.request<SavingsDetailData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/savings/${accountId}`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Use /v1/accounts/{accountId} instead
		 *
		 * @tags credit line
		 * @name CreditLineList
		 * @request GET:/v1/credit-line
		 * @deprecated
		 */
		creditLineList: (params: RequestParams = {}) =>
			this.request<CreditLineListData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/credit-line`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Набор данных для тултипа с подсказкой для клиента и его тип
		 *
		 * @tags credit line
		 * @name CreditLineStateList
		 * @request GET:/v1/credit-line/state
		 * @deprecated
		 */
		creditLineStateList: (params: RequestParams = {}) =>
			this.request<
				CreditLineStateListData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/credit-line/state`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags credit line
		 * @name CreditLineContractList
		 * @request GET:/v1/credit-line/contract
		 * @deprecated
		 */
		creditLineContractList: (params: RequestParams = {}) =>
			this.request<
				CreditLineContractListData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/credit-line/contract`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Use /v2/accounts instead
		 *
		 * @tags credit lines
		 * @name CreditLinesList
		 * @request GET:/v1/credit-lines
		 * @deprecated
		 */
		creditLinesList: (params: RequestParams = {}) =>
			this.request<CreditLinesListData, BadRequest | RequestedResourceNotFound>(
				{
					path: `/v1/credit-lines`,
					method: 'GET',
					...params,
				}
			),

		/**
		 * @description Use /v1/accounts/{accountId} instead
		 *
		 * @tags credit lines
		 * @name CreditLinesDetail
		 * @request GET:/v1/credit-lines/{accountId}
		 * @deprecated
		 */
		creditLinesDetail: (accountId: string, params: RequestParams = {}) =>
			this.request<
				CreditLinesDetailData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/credit-lines/${accountId}`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Набор данных для тултипа с подсказкой для клиента и его тип
		 *
		 * @tags credit lines
		 * @name CreditLinesStateDetail
		 * @request GET:/v1/credit-lines/{accountId}/state
		 */
		creditLinesStateDetail: (accountId: string, params: RequestParams = {}) =>
			this.request<
				CreditLinesStateDetailData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/credit-lines/${accountId}/state`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Use /v1/accounts/{accountId} instead
		 *
		 * @tags loan
		 * @name GetLoan
		 * @request GET:/v1/loan/{loanId}
		 * @deprecated
		 */
		getLoan: (loanId: string, params: RequestParams = {}) =>
			this.request<GetLoanData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/loan/${loanId}`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags loan
		 * @name GetPaymentsSchedule
		 * @request GET:/v1/loan/{loanId}/payments/schedule
		 */
		getPaymentsSchedule: (loanId: string, params: RequestParams = {}) =>
			this.request<
				GetPaymentsScheduleData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/loan/${loanId}/payments/schedule`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags loan
		 * @name GetPaymentsHistory
		 * @request GET:/v1/loan/{loanId}/payments/history
		 * @deprecated
		 */
		getPaymentsHistory: (
			{ loanId, ...query }: GetPaymentsHistoryParams,
			params: RequestParams = {}
		) =>
			this.request<
				GetPaymentsHistoryData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/loan/${loanId}/payments/history`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags loan
		 * @name GetOrder
		 * @request GET:/v1/loan/{loanId}/order
		 */
		getOrder: (loanId: string, params: RequestParams = {}) =>
			this.request<GetOrderData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/loan/${loanId}/order`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags loan
		 * @name GetContract
		 * @request GET:/v1/loan/{loanId}/contract
		 */
		getContract: (loanId: string, params: RequestParams = {}) =>
			this.request<GetContractData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/loan/${loanId}/contract`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags loan
		 * @name GetAllPaymentsHistory
		 * @request GET:/v1/loan/payments/history
		 * @deprecated
		 */
		getAllPaymentsHistory: (
			query: GetAllPaymentsHistoryParams,
			params: RequestParams = {}
		) =>
			this.request<
				GetAllPaymentsHistoryData,
				BadRequest | RequestedResourceNotFound
			>({
				path: `/v1/loan/payments/history`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name GetCards
		 * @request GET:/v1/cards
		 */
		getCards: (query: GetCardsParams, params: RequestParams = {}) =>
			this.request<GetCardsData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/cards`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name IssueCard
		 * @request POST:/v1/cards/issue
		 */
		issueCard: (query: IssueCardParams, params: RequestParams = {}) =>
			this.request<
				IssueCardData,
				InsufficientFundsForCardIssuing | ErrorResponse
			>({
				path: `/v1/cards/issue`,
				method: 'POST',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name GetCard
		 * @request GET:/v1/cards/{cardId}
		 */
		getCard: (cardId: string, params: RequestParams = {}) =>
			this.request<GetCardData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/cards/${cardId}`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name GetCard2
		 * @request GET:/v1/cards/{cardId}/requisites
		 * @originalName getCard
		 * @duplicate
		 */
		getCard2: (cardId: string, params: RequestParams = {}) =>
			this.request<GetCard2Data, BadRequest | RequestedResourceNotFound>({
				path: `/v1/cards/${cardId}/requisites`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name LinkCard
		 * @request POST:/v1/cards/{cardId}/activate
		 */
		linkCard: (
			cardId: string,
			data: ActivateCardRequest,
			params: RequestParams = {}
		) =>
			this.request<
				LinkCardData,
				BadRequest | RequestedResourceNotFound | CardIsAlreadyActive
			>({
				path: `/v1/cards/${cardId}/activate`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name LinkCardMm
		 * @request POST:/v1/cards/activate/mm
		 */
		linkCardMm: (
			query: LinkCardMmParams,
			data: ActivateMatchmoveCardRequest,
			params: RequestParams = {}
		) =>
			this.request<
				LinkCardMmData,
				BadRequest | RequestedResourceNotFound | CardIsAlreadyActive
			>({
				path: `/v1/cards/activate/mm`,
				method: 'POST',
				query: query,
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name BlockCard
		 * @request POST:/v1/cards/{cardId}/block
		 */
		blockCard: (
			cardId: string,
			data: BlockCardRequest,
			params: RequestParams = {}
		) =>
			this.request<BlockCardData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/cards/${cardId}/block`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name SetPin
		 * @request POST:/v1/cards/{cardId}/pin
		 */
		setPin: (cardId: string, data: SetPinRequest, params: RequestParams = {}) =>
			this.request<SetPinData, BadRequest | RequestedResourceNotFound>({
				path: `/v1/cards/${cardId}/pin`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name SetPin2
		 * @request POST:/v1/cards/{cardId}/freeze
		 * @originalName setPin
		 * @duplicate
		 */
		setPin2: (cardId: string, params: RequestParams = {}) =>
			this.request<SetPin2Data, BadRequest | RequestedResourceNotFound>({
				path: `/v1/cards/${cardId}/freeze`,
				method: 'POST',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cards
		 * @name SetPin3
		 * @request POST:/v1/cards/{cardId}/unfreeze
		 * @originalName setPin
		 * @duplicate
		 */
		setPin3: (cardId: string, params: RequestParams = {}) =>
			this.request<SetPin3Data, BadRequest | RequestedResourceNotFound>({
				path: `/v1/cards/${cardId}/unfreeze`,
				method: 'POST',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags operations
		 * @name OperationsList
		 * @request GET:/v1/operations
		 * @deprecated
		 */
		operationsList: (query: OperationsListParams, params: RequestParams = {}) =>
			this.request<OperationsListData, any>({
				path: `/v1/operations`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags operations
		 * @name OperationsDetail
		 * @request GET:/v1/operations/{operationId}
		 * @deprecated
		 */
		operationsDetail: (
			{ operationId, ...query }: OperationsDetailParams,
			params: RequestParams = {}
		) =>
			this.request<OperationsDetailData, any>({
				path: `/v1/operations/${operationId}`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * @description Confirm operation using Otp
		 *
		 * @tags confirmation
		 * @name ConfirmOtpCreate
		 * @request POST:/v1/confirm/otp
		 */
		confirmOtpCreate: (
			data: ConfirmationCompleteRequest,
			params: RequestParams = {}
		) =>
			this.request<
				ConfirmOtpCreateData,
				ConfirmationErrorResponse | ConflictErrorResponse
			>({
				path: `/v1/confirm/otp`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Confirm operation using magic link
		 *
		 * @tags confirmation
		 * @name ConfirmMagicLinkCreate
		 * @request POST:/v1/confirm/magic-link
		 */
		confirmMagicLinkCreate: (
			data: ConfirmationMagicLinkRequest,
			params: RequestParams = {}
		) =>
			this.request<ConfirmMagicLinkCreateData, any>({
				path: `/v1/confirm/magic-link`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cash loan
		 * @name CreateCashApplication
		 * @request POST:/v1/applications/cash
		 * @deprecated
		 */
		createCashApplication: (
			data: NewApplicationRequest,
			params: RequestParams = {}
		) =>
			this.request<CreateCashApplicationData, any>({
				path: `/v1/applications/cash`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cash loan
		 * @name GetApplication
		 * @request GET:/v1/applications/cash
		 * @deprecated
		 */
		getApplication: (params: RequestParams = {}) =>
			this.request<GetApplicationData, any>({
				path: `/v1/applications/cash`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cash loan
		 * @name StartCashConfirmations
		 * @request POST:/v1/applications/cash/confirmations/start
		 * @deprecated
		 */
		startCashConfirmations: (
			data: StartCashConfirmRequest,
			params: RequestParams = {}
		) =>
			this.request<StartCashConfirmationsData, any>({
				path: `/v1/applications/cash/confirmations/start`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cash loan
		 * @name CompletionCashConfirmations
		 * @request POST:/v1/applications/cash/confirmations/complete
		 * @deprecated
		 */
		completionCashConfirmations: (
			data: CompletionCashConfirmRequest,
			params: RequestParams = {}
		) =>
			this.request<CompletionCashConfirmationsData, any>({
				path: `/v1/applications/cash/confirmations/complete`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cash loan
		 * @name GetDisbursementProviders
		 * @request GET:/v1/disbursement/providers
		 * @deprecated
		 */
		getDisbursementProviders: (params: RequestParams = {}) =>
			this.request<GetDisbursementProvidersData, any>({
				path: `/v1/disbursement/providers`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BAF (bring a friend)
		 * @name GetBafReferral
		 * @request GET:/v1/baf
		 * @deprecated
		 */
		getBafReferral: (params: RequestParams = {}) =>
			this.request<GetBafReferralData, void>({
				path: `/v1/baf`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags challenges
		 * @name GetChallenges
		 * @request GET:/v1/challenges
		 */
		getChallenges: (params: RequestParams = {}) =>
			this.request<GetChallengesData, any>({
				path: `/v1/challenges`,
				method: 'GET',
				...params,
			}),
	};
	v2 = {
		/**
		 * No description
		 *
		 * @tags accounts
		 * @name GetAccountsV2
		 * @request GET:/v2/accounts
		 */
		getAccountsV2: (params: RequestParams = {}) =>
			this.request<GetAccountsV2Data, any>({
				path: `/v2/accounts`,
				method: 'GET',
				...params,
			}),
	};
	internal = {
		/**
		 * No description
		 *
		 * @tags accounts internal
		 * @name GetAccountsInternal
		 * @request GET:/internal/v1/accounts
		 */
		getAccountsInternal: (params: RequestParams = {}) =>
			this.request<GetAccountsInternalData, any>({
				path: `/internal/v1/accounts`,
				method: 'GET',
				...params,
			}),
	};
}
