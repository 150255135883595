import styled from '@emotion/styled';
import { Skeleton } from '@fhl-world/salmon-ui';
import { formatMoney } from '@utils/formatMoney/formatMoney';

import { Money } from 'src/types/generated/pos';

const Container = styled.p`
	//styleName: Desktop/Heading 4;
	font-size: 28px;
	font-weight: 800;
	line-height: 32px;
	text-align: center;
	margin: 0 0 16px;
`;

type AmountTitleProps = {
	amount?: Money;
};

const AmountTitle = (props: AmountTitleProps) => {
	const { amount } = props;

	if (!amount) {
		return (
			<Skeleton
				width={'7ch'}
				sx={{ fontSize: '28px', lineHeight: '32px', margin: '0 auto' }}
				variant="text"
			/>
		);
	}
	return <Container>{formatMoney(amount)}</Container>;
};

export default AmountTitle;
