import { useCallback } from 'react';
import { MenuItem } from '@fhl-world/salmon-ui';
import styled from '@emotion/styled';
import { track } from '@amplitude/analytics-browser';

import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { Money } from '../../../../../types/generated/pos';

const texts: Record<AmountSuggestProps['type'], string> = {
	required:
		'The minimum payment. Pay on or before your due date to continue using your credit line',
	outstanding: 'Your outstanding balance. Pay this to clear all debts',
};

const RootMenuItem = styled(MenuItem)`
	padding: 8px 16px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2px;
`;

const AmountText = styled.p`
	font-size: 15px;
	font-style: normal;
	font-weight: 800;
	line-height: 15px;
	margin: 0;
`;

const Badge = styled.div`
	display: flex;
	padding: 2px 7.5px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	margin: 0;
	border-radius: 30px;
	background: var(--day-status-sucess-fill, #4ac99b);
	margin-left: 2px;
`;

const BadgeText = styled.p`
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 145.455% */
	letter-spacing: 0.4px;
	color: var(--day-text-icons-text-01-inverse, #fff);
	text-align: center;
	margin: 0;
`;

const Caption = styled.p`
	color: var(--day-text-icons-text-02, rgba(27, 31, 59, 0.65));
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	white-space: normal;
	margin: 0;
`;

type AmountSuggestProps = {
	type: 'required' | 'outstanding';
	label: string;
	value: Money;
	onClick: (value: Money) => void;
};

const AmountSuggest = (props: AmountSuggestProps) => {
	const { type, value, label, onClick } = props;

	const handleClick = useCallback(() => {
		const eventOptions =
			type === 'required' ? { minimal: value } : { outstanding: value };
		track(AmplitudeEvents.PaymentOptions_Tap, { amount: eventOptions });

		onClick(value);
	}, [value, onClick]);

	return (
		<RootMenuItem onClick={handleClick}>
			<Container>
				<Wrapper>
					<AmountText>{label}</AmountText>
					{type === 'required' && (
						<Badge>
							<BadgeText>Required payment</BadgeText>
						</Badge>
					)}
				</Wrapper>
				<Caption>{texts[type]}</Caption>
			</Container>
		</RootMenuItem>
	);
};

export default AmountSuggest;
