import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const Container = styled.div`
	display: flex;
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
	border-radius: 24px;
	padding: 20px;
	cursor: pointer;
`;

const InnerContainer = styled.div`
	display: flex;
`;

const IconContainer = styled.div<{ success: boolean }>`
	background: ${(p) => (p.success ? '#E3F9EE' : '#edecec')};
	border-radius: 16px;
	margin-right: 16px;
	padding: 12px;
	display: flex;
`;

const TypeCaption = styled.div`
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
	color: #828282;
	margin-bottom: 4px;
`;

const AmountContainer = styled.div`
	font-weight: 800;
	font-size: 17px;
	line-height: 28px;
	color: #302e2e;
	margin-bottom: 2px;
`;

const DateContainer = styled.div`
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #302e2e;
`;

export interface AccountItemProps {
	// loan: ApiLoanData;
	title: string;
	amountText: string;
	caption: string;
	iconSrc: string;
	isClosed?: boolean;
	link: string;
	onClick: () => void;
}

const CreditItem = (props: AccountItemProps) => {
	const { title, amountText, caption, iconSrc, isClosed, link, onClick } =
		props;

	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		navigate(link);
		onClick();
	}, [navigate, link, onClick]);

	return (
		<Container onClick={handleClick}>
			<InnerContainer>
				<div>
					<IconContainer success={!!isClosed}>
						<img width={24} src={iconSrc} alt="icon" />
					</IconContainer>
				</div>

				<div>
					<TypeCaption>{title}</TypeCaption>
					<AmountContainer>{amountText}</AmountContainer>
					<DateContainer>{caption}</DateContainer>
				</div>
			</InnerContainer>
		</Container>
	);
};

export default CreditItem;
