import { FC } from 'react';
import styled from '@emotion/styled';
import {
	Money,
	OperationDirectionEnum,
	OperationStatusEnum,
} from '../../../types/generated/pos';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { getTime } from '@utils/dates/formatDates';
import { CdnIcon } from '@fhl-world/salmon-ui';

import Union from '@assets/icons/Union.svg';
import Time from '@assets/icons/Time.svg';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 0;
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
`;

const RowInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ItemName = styled.span`
	font-size: 15px;
	line-height: 24px;
	font-weight: 800;
`;

const ItemDate = styled.span`
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
	display: block;
`;

const CompletedInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #34b41f;
`;

const FailedInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #dd4c1e;
`;

const StatusIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 6px;
`;

const PendingInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
`;

const Transfer = ({ amount }: AmountViewProps) => (
	<FailedInner>–{formatMoney(amount)}</FailedInner>
);

const PaymentCompleted = ({ amount }: AmountViewProps) => (
	<CompletedInner>+{formatMoney(amount)}</CompletedInner>
);

const PaymentFailed = ({ amount }: AmountViewProps) => (
	<FailedInner>
		+{formatMoney(amount)}
		<StatusIconWrapper>
			<img src={Union} alt="logo" width={16} height={16} />
		</StatusIconWrapper>
	</FailedInner>
);

const PaymentPending = ({ amount }: AmountViewProps) => (
	<PendingInner>
		+{formatMoney(amount)}
		<StatusIconWrapper>
			<img src={Time} alt="logo" width={16} height={16} />
		</StatusIconWrapper>
	</PendingInner>
);

type AmountViewProps = { amount: Money };
const AmountViewComponents: Record<
	OperationDirectionEnum,
	Record<OperationStatusEnum, FC<AmountViewProps>>
> = {
	[OperationDirectionEnum.OUT]: {
		[OperationStatusEnum.SUCCESSFUL]: Transfer,
		[OperationStatusEnum.FAILED]: Transfer,
		[OperationStatusEnum.PENDING]: Transfer,
	},
	[OperationDirectionEnum.IN]: {
		[OperationStatusEnum.SUCCESSFUL]: PaymentCompleted,
		[OperationStatusEnum.FAILED]: PaymentFailed,
		[OperationStatusEnum.PENDING]: PaymentPending,
	},
};

export type HistoryItemProps = {
	id: string;
	direction: OperationDirectionEnum;
	iconCode: string;
	providerName: string;
	timestamp: Date;
	amount: Money;
	status: OperationStatusEnum;
};

const HistoryItem = (props: HistoryItemProps) => {
	const { direction, iconCode, providerName, timestamp, amount, status } =
		props;

	const AmountView = AmountViewComponents[direction][status];

	return (
		<RowItem>
			<RowInner>
				<IconWrapper>
					<CdnIcon code={iconCode} width={40} height={40} />
				</IconWrapper>
				<div>
					<ItemName>{providerName}</ItemName>
					<ItemDate>{getTime(timestamp)}</ItemDate>
				</div>
			</RowInner>
			<AmountView amount={amount} />
		</RowItem>
	);
};

export default HistoryItem;
