import { isError } from '../isError';
import { parseErrorStack } from './parseErrorStack';

type Params = {
	message: Event | string;
	source?: string;
	lineno?: number;
	colno?: number;
	error?: unknown;
	prefix?: string;
};

// Make pretty stack, if error is not Error (throw '123')"
export const createErrorStack = (params: Params): string => {
	const {
		prefix = 'Invalid error object',
		message,
		source,
		lineno,
		colno,
		error,
	} = params;

	if (isError(error)) {
		return parseErrorStack(error);
	}

	const details = {
		source,
		lineno,
		colno,
		error,
	};

	const prettyDetails = Object.entries(details).reduce((acc, [key, value]) => {
		const stringifyValue =
			typeof value === 'string' ? value : JSON.stringify(value);

		const pair = `    ${key}: ${stringifyValue}`;
		return acc ? acc + '\n' + pair : pair;
	}, '');

	return `${prefix}: ${message}\n${prettyDetails}`;
};
