import { LogFields } from './sendLogEvent';

export const left30symbols = (str: string, diff: number) => {
	if (str.length - diff > 30) {
		return str.slice(0, -(diff + 3)) + '...';
	} else {
		return str.slice(0, 27) + '...';
	}
};

export const toShortEvent = (
	event: Record<string, string | undefined>
): Record<string, string | undefined> => {
	try {
		const eventLength = JSON.stringify(event).length;
		const diff = eventLength - 1024;

		if (diff < 1) {
			return event;
		}

		if ((event.error?.length || 0) > 30) {
			event.error = left30symbols(event.error as string, diff);
			return toShortEvent(event);
		}

		if ((event.msg?.length || 0) > 30) {
			event.msg = left30symbols(event.msg as string, diff);
			return toShortEvent(event);
		}

		if (event.referer) {
			delete event.referer;
			return toShortEvent(event);
		}

		if (event.path) {
			delete event.path;
			return toShortEvent(event);
		}

		if (event.error) {
			delete event.error;
			return toShortEvent(event);
		}
	} catch {
		return {
			[LogFields.appName]: event[LogFields.appName],
			[LogFields.namespace]: event[LogFields.namespace],
			msg: 'Can not parse event to short event',
		};
	}

	return {
		[LogFields.appName]: event[LogFields.appName],
		[LogFields.namespace]: event[LogFields.namespace],
		msg: 'Event is too long',
	};
};
