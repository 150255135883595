/* eslint-disable no-console */
import { LOGS_URL, isDev } from '@config/envVariables';
import { toShortEvent } from './toShortEvent';
import { removeHostFromStack } from './removeHostFromStack';

type EventToServer = {
	level: 'error' | 'warn' | 'info' | 'debug';
	msg: string;
	error?: string;
};

export enum LogFields {
	appName = 'app',
	namespace = 'group',
}
export const appName = 'client';

const makeRetryChecker = () => {
	let lastEvent: string | undefined;
	return (event: EventToServer) => {
		if (lastEvent === JSON.stringify(event)) {
			return true;
		}
		lastEvent = JSON.stringify(event);
		return false;
	};
};
const isRetry = makeRetryChecker();

export const sendLogEvent = async (event: EventToServer): Promise<void> => {
	const logEvent = {
		[LogFields.appName]: appName,
		[LogFields.namespace]: 'web',
		path:
			window.location.pathname + window.location.search + window.location.hash,
		referer: document.referrer || undefined,
		...event,
		sessionId: window.salmonsession_id,
	};

	if (isRetry(logEvent)) {
		return;
	}

	const eventWithouHost = {
		...logEvent,
		error: event.error ? removeHostFromStack(event.error) : undefined,
	};
	const shortedEvent = toShortEvent(eventWithouHost);

	const url = new URL(LOGS_URL);
	url.searchParams.append('type', logEvent.level);

	if (isDev) {
		console.log('[sendLog]:', shortedEvent);
		return;
	}

	fetch(url, {
		method: 'POST',
		body: JSON.stringify(shortedEvent),
		headers: {
			'Content-Type': 'application/json',
		},
	}).catch((error) => {
		console.error('Failed to send log', error);
	});
};
