import { useNavigate } from 'react-router-dom';
import { NotFoundPage } from '@fhl-world/salmon-ui';

export const NotFound = () => {
	const navigate = useNavigate();

	const goToMainPage = () => {
		navigate('/');
	};

	return <NotFoundPage goBackToMainPage={goToMainPage} />;
};
