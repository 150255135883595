import {
	CreditLineResponse,
	CreditLineStateResponse,
	LoanResponse,
} from '../../../types/generated/pos';
import { getCreditMinPayment } from './getCreditMinPayment';

export type AccountEventOptions = {
	loanNumber: string;
	loanType: 'CASH' | 'POS' | 'CreditLine';
	nextPayment_date?: Required<LoanResponse>['nextPayment']['dueDate'];
	nextPayment_amount?: Required<LoanResponse>['nextPayment']['amount'];
};

export const getLoanEventOptions = (
	loan: LoanResponse
): AccountEventOptions => {
	return {
		loanNumber: loan.loanNumber,
		loanType: loan.loanType,
		nextPayment_date: loan.nextPayment?.dueDate,
		nextPayment_amount: loan.nextPayment?.amount,
	};
};

export const getCreditEventOptions = (
	credit: CreditLineResponse,
	creditStatus?: CreditLineStateResponse | null
): AccountEventOptions => {
	return {
		loanNumber: credit.accountId,
		loanType: 'CreditLine',
		nextPayment_date: credit.dueDate,
		nextPayment_amount: creditStatus
			? getCreditMinPayment(creditStatus)?.amount
			: undefined,
	};
};
