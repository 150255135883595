import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { staticURLs } from '@config/staticURLs';
import styled from '@emotion/styled';
import { Button } from '@fhl-world/salmon-ui';

const Container = styled.section`
	background-color: #ea5347;
`;

const InnerContainer = styled.section`
	max-width: 380px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;
	margin: auto;
	padding: 12px;
`;

const InfoContainer = styled.div`
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 800;
`;

const InfoText = styled.span`
	font-size: 13px;
`;

const InfoWarningText = styled.span`
	font-size: 11px;
`;

const StyledButton = styled(Button)`
	height: 32px;
	font-size: 13px;
	background-color: white;
	color: #ea5347;
	:hover {
		background-color: white;
		color: #ea5347;
	}
`;

const ButtonWrapper = styled.div`
	flex-shrink: 0;
`;

const ButtonInnerWrapper = styled.div`
	margin: auto 12px;
`;

const BannerGetTheApp = () => {
	return (
		<Container>
			<InnerContainer>
				<InfoContainer>
					<InfoText>
						New products and features will
						<br /> only be available in our app <br />
					</InfoText>
					<InfoWarningText>
						The web version is no longer supported
					</InfoWarningText>
				</InfoContainer>
				<ButtonWrapper>
					<ButtonInnerWrapper>
						<StyledButton
							variant="contained"
							size={'small'}
							onClick={() => {
								track(AmplitudeEvents.GetTheApp_Tap);
							}}
							href={staticURLs.salmonLaunch}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							target="_blank"
						>
							Get the app
						</StyledButton>
					</ButtonInnerWrapper>
				</ButtonWrapper>
			</InnerContainer>
		</Container>
	);
};

export default BannerGetTheApp;
