import { Money } from '../../types/generated/pos';

const getPeso = (sentimo: Money): number => {
	const isNum = typeof sentimo === 'number';

	if (isNum) {
		return sentimo / 100;
	}

	return sentimo.amount;
};

const getCurrency = (sentimo: Money): string => {
	const isNum = typeof sentimo === 'number';
	return isNum ? 'PHP' : sentimo.currency || 'PHP';
};

// {amount: 1234.1, currency: 'PHP'} => ₱1,234.10
export const formatMoney = (sentimo: Money): string => {
	const peso = getPeso(sentimo);

	return peso.toLocaleString('en-US', {
		minimumFractionDigits: 2,
		style: 'currency',
		currency: getCurrency(sentimo),
	});
};
