import { useEffect } from 'react';
import { track } from '@amplitude/analytics-browser';

import { AmplitudeEvents } from '@utils/amplitudeEvents';

export const useSuccessRedirect = (location?: string) => {
	useEffect(() => {
		if (!location) {
			return;
		}

		track(AmplitudeEvents.Redirect_Opened, { url: location });
		window.open(location, '_self');
	}, [location]);
};
