import styled from '@emotion/styled';
import { notificationConfig, PermittedNotificationTypes } from './config';

interface NotificationProps {
	msgCmp: React.ReactNode;
	type: PermittedNotificationTypes;
}

const Container = styled.div<{ notificationType: PermittedNotificationTypes }>`
	display: flex;
	align-items: start;
	gap: 12px;
	font-size: 13px;
	padding: 12px 16px;
	line-height: 20px;
	font-weight: 500;
	border-radius: 12px;
	background-color: ${(p) => notificationConfig[p.notificationType].bgColor};
`;

const Image = styled.img`
	margin-top: 2px;
`;

const Notification = ({ msgCmp, type }: NotificationProps) => {
	return (
		<Container notificationType={type} data-testid={'notification-container'}>
			<Image src={notificationConfig[type].icon} alt="notification icon" />
			{msgCmp}
		</Container>
	);
};

export default Notification;
