import { sendError } from './sendLog';
import { parseErrorMsg } from './utils/parseErrorMsg';
import { createErrorStack } from './utils/createErrorStack';
import { parseErrorStack } from './utils/parseErrorStack';
import { isDev } from '@config/envVariables';

export const handleBrowserError = () => {
	window.addEventListener('error', (event: ErrorEvent) => {
		if (isDev) {
			console.error('[AddEventListener.error]:', event);
		}

		const msg = event.message || parseErrorMsg(event.error);
		sendError({
			msg: `[Browser] ${msg}`,
			error: createErrorStack({
				error: event.error,
				message: event.message,
				source: event.filename,
				lineno: event.lineno,
				colno: event.colno,
			}),
		});
	});

	window.onerror = (
		message,
		source,
		lineno,
		colno,
		error: Error | undefined
	) => {
		if (isDev) {
			console.error('[Window.onError]', {
				message,
				source,
				lineno,
				colno,
				error,
			});
		}

		const msg = message || parseErrorMsg(error);
		sendError({
			msg: `[Browser] ${msg}`,
			error: createErrorStack({
				error,
				message,
				source,
				lineno,
				colno,
			}),
		});

		return true;
	};

	window.addEventListener(
		'unhandledrejection',
		(event: PromiseRejectionEvent) => {
			if (isDev) {
				console.error('[Unhandledrejection]:', event);
			}

			const msg = parseErrorMsg(event.reason);
			sendError({
				msg: `[Browser] Unhandled Rejection: ${msg}`,
				error: parseErrorStack(event.reason),
			});

			event.preventDefault();
		}
	);

	document.addEventListener(
		'securitypolicyviolation',
		(event: SecurityPolicyViolationEvent) => {
			if (isDev) {
				console.error('[CSP Error]', event);
			}

			const message = `Refused connection "${event.blockedURI}" by "${event.violatedDirective}"`;
			sendError({
				msg: `[CSP] ${message}`,
				error: createErrorStack({
					prefix: 'CSP Error',
					error: event,
					message,
					source: event.sourceFile,
					colno: event.columnNumber,
					lineno: event.lineNumber,
				}),
			});

			event.preventDefault();
		}
	);
};
