import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { NotFound } from '../NotFound/NotFound';
import AccountList from './AccountList/AccountList';
import { DataWithState } from '@api/models/common';
import {
	AccountsResponse,
	CreditLineStateResponse,
} from '../../types/generated/pos';
import { useRedirectToAccount } from './utils/useRedirectToAccount';

interface MainPageProps {
	accountsState: DataWithState<AccountsResponse>;
	creditStatusState: DataWithState<CreditLineStateResponse>;
}

function MainPage(props: MainPageProps) {
	const { accountsState, creditStatusState } = props;

	useRedirectToAccount(accountsState);

	if (accountsState.loading) {
		return <LoadingScreen />;
	}

	if (accountsState.data) {
		return (
			<AccountList
				{...accountsState.data}
				creditStatus={creditStatusState.data}
			/>
		);
	}

	return <NotFound />;
}

export default MainPage;
