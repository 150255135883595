import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { getUrlToComeBack } from '@config/routes';

export interface FetchOptions {
	method?: string;
	body?: string;
	headers?: {
		'Content-Type': string;
	};
}

export const requestHttp = async (
	url: string,
	accessToken: string,
	unauthorizedCallback: Auth0ContextInterface['getAccessTokenSilently'],
	logoutCallback: Auth0ContextInterface['logout'],
	options?: FetchOptions,
	isFirstCall = true
): Promise<Response> => {
	return fetch(url, {
		...options,
		cache: 'no-cache',
		headers: {
			...options?.headers,
			Authorization: 'Bearer ' + accessToken,
			'x-session-id': window.salmonsession_id,
			'X-App-Name': 'client',
			'X-Device-Type': 'web',
		},
	}).then((response) => {
		if (response.status === 401) {
			if (isFirstCall) {
				return unauthorizedCallback()
					.then((token: string) => {
						window.accessToken = token;

						return requestHttp(
							url,
							token,
							unauthorizedCallback,
							logoutCallback,
							options,
							false
						);
					})
					.catch((err) => {
						logoutCallback({ logoutParams: { returnTo: getUrlToComeBack() } });
						// eslint-disable-next-line no-console
						console.error('Unauthorized error', err);
						throw new Error('Unauthorized');
					});
			} else {
				logoutCallback({ logoutParams: { returnTo: getUrlToComeBack() } });
				// eslint-disable-next-line no-console
				console.error('Unauthorized error');
				throw new Error('Unauthorized');
			}
		} else {
			return response;
		}
	});
};

export const useRequestHttp = (
	url: string,
	accessToken: string,
	options?: FetchOptions,
	isFirstCall = true
) => {
	const { getAccessTokenSilently, logout } = useAuth0();

	return requestHttp(
		url,
		accessToken,
		getAccessTokenSilently,
		logout,
		options,
		isFirstCall
	);
};
