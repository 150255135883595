import { BASE_URL } from '@utils/common';
import { PaymentsRepaymentsProvidersListData } from 'src/types/generated/pos';
import { useFetch } from '../utilsService/utilsApiService';

export const useGetProviders = () => {
	return useFetch<PaymentsRepaymentsProvidersListData>(
		// why we need to use showLinked https://fhl-world.atlassian.net/browse/WEB-246
		`${BASE_URL}/v1/payments/repayments/providers?showLinked=false `
	);
};
