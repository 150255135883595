import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as amplitude from '@amplitude/analytics-browser';
import { v4 } from 'uuid';

import RoutePage from './pages/RoutePage/RoutePage';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { AuthContext } from '@hooks/Auth';
import { REACT_APP_AMPLITUDE_API_KEY } from '@utils/common';
import { parseJwt } from '@utils/parseJwt';
import { extractRedirectPath, getUrlToComeBack } from '@config/routes';
import { sendInfo } from '@utils/logger/sendLog';

function App() {
	const {
		isLoading,
		isAuthenticated,
		error,
		loginWithRedirect,
		logout,
		getAccessTokenSilently,
	} = useAuth0();

	const [accessToken, setAccessToken] = useState('');

	const navigate = useNavigate();
	useEffect(() => {
		const redirectPath = extractRedirectPath();
		if (redirectPath) {
			navigate(redirectPath);
		}
	}, []);

	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			getAccessTokenSilently()
				.then((token) => {
					setAccessToken(token);
					const parsedToken = parseJwt(token);
					amplitude.init(REACT_APP_AMPLITUDE_API_KEY, parsedToken?.iid, {
						minIdLength: 1,
						defaultTracking: { sessions: true },
					});

					const uuid = v4();
					const identifyEvent = new amplitude.Identify();
					identifyEvent.set('salmonsession_id', uuid);
					amplitude.identify(identifyEvent);

					window.accessToken = token;
					window.salmonsession_id = uuid;
				})
				.catch(() => {
					sendInfo({ msg: 'Unauthorized' });
					logout({ logoutParams: { returnTo: getUrlToComeBack() } });
				});
		}
	}, [isAuthenticated, isLoading, getAccessTokenSilently]);

	useEffect(() => {
		if (error) {
			console.error('Unauthorized error', error);
		} else if (!isAuthenticated && !isLoading) {
			void loginWithRedirect({
				appState: { returnTo: window.location.toString() },
			});
		}
	}, [isAuthenticated, isLoading, loginWithRedirect, error]);

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isAuthenticated && !isLoading && accessToken) {
		return (
			<AuthContext.Provider value={accessToken}>
				<RoutePage />
			</AuthContext.Provider>
		);
	} else {
		return <LoadingScreen />;
	}
}

export default App;
