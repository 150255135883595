import { useFetch } from '@api/services/utilsService/utilsApiService';
import {
	type ApiOrderData,
	type ApiPaymentScheduleData,
} from '@api/models/loan';
import { BASE_URL } from '@utils/common';

export const useOrderApi = (loanId: string) => {
	return useFetch<ApiOrderData>(
		loanId ? `${BASE_URL}/v1/loan/${loanId}/order` : null
	);
};

export const usePaymentScheduleApi = (loanId: string) => {
	return useFetch<ApiPaymentScheduleData>(
		loanId ? `${BASE_URL}/v1/loan/${loanId}/payments/schedule` : null
	);
};
