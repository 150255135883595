import { useNavigate } from 'react-router-dom';
import FailedPayment from '@assets/icons/PaymentFailed.svg';
import styled from '@emotion/styled';
import { Button } from '@fhl-world/salmon-ui';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
`;

const Title = styled.h3`
	font-size: 24px;
	line-height: 28px;
	margin: 24px 0 16px 0;
	text-align: center;
	font-weight: 800;
`;

const Info = styled.span`
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	margin-bottom: 24px;
	text-align: center;
`;

const MainPageButton = styled(Button)`
	font-weight: 800;
`;

const PaymentFailed = () => {
	const navigate = useNavigate();

	const goToMainPage = () => {
		navigate('/');
	};

	return (
		<Container>
			<img src={FailedPayment} height={96} width={96} alt="logo" />
			<Title>Payment Failed</Title>
			<Info>
				You haven’t been charged. Try again or use a different payment method
			</Info>
			<MainPageButton
				size="large"
				variant="contained"
				fullWidth
				onClick={goToMainPage}
			>
				Try again
			</MainPageButton>
		</Container>
	);
};

export default PaymentFailed;
