import styled from '@emotion/styled';

interface ProfileElementData {
	caption: string;
	data: string;
}

const Container = styled.div`
	padding: 20px 0;
`;

const Caption = styled.span`
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
	color: #1b1f3b66;
`;

const Text = styled.p`
	font-size: 15px;
	font-weight: 800;
	line-height: 24px;
	color: #1b1f3bcc;
	margin: 0;
`;

function ProfileElement(props: ProfileElementData) {
	const { caption, data } = props;
	return (
		<Container>
			<Caption>{caption}</Caption>
			<Text>{data}</Text>
		</Container>
	);
}

export default ProfileElement;
