export interface ApiOrderData {
	storeAddress: string;
	storeName: string;
	items: ApiOrderItems[];
	basket: {
		amount: number;
	};
	loanTerms: {
		downPayment: number;
		loanAmount: number;
		loanTerms: number;
		monthlyPayment: number;
	};
	total: number;
}

export interface ApiOrderItems {
	name: string;
	price: number;
	quantity: number;
	total: number;
}

export interface ApiPaymentScheduleData {
	leftToPay: number;
	schedule: ApiScheduleItemData[];
}

export interface ApiScheduleItemData {
	dueDate: string;
	base: number;
	paid: number;
	fee: number;
	status: ApiScheduleStatusCodes;
}

export enum ApiScheduleStatusCodes {
	PAID = 'PAID',
	MISSED = 'MISSED',
	CURRENT = 'CURRENT',
	PENDING = 'PENDING',
}

export interface ApiHistoryProviderData {
	id: string;
	name: string;
	code: ApiPaymentCodes | string; // if there will be more codes than frontend knows
	fee: number;
}

export enum ApiPaymentCodes {
	gcash = 'gcash',
	cards = 'cards',
	'7eleven' = '7eleven',
	cebuana = 'cebuana',
	maya = 'maya',
	direct_debit = 'direct_debit',
}
