import { useCallback } from 'react';
import styled from '@emotion/styled';
import { Button } from '@fhl-world/salmon-ui';

import { ReactComponent as Download } from '@assets/icons/Download.svg';
import { trackDownload } from '../../utils/analytics';
import { downloadImage } from './utils/downloadImage';

const ButtonWrapper = styled.div`
	position: sticky;
	bottom: 0;
	padding: 24px 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
`;

const Icon = styled(Download)<{ isLoading?: boolean }>`
	path {
		fill: ${(props) => (props.isLoading ? 'rgba(0, 0, 0, 0.26)' : 'white')};
	}
`;

type DownloadButtonProps = {
	isLoading?: boolean;
	parentRef?: React.RefObject<HTMLDivElement>;
};

const DownloadButton = (props: DownloadButtonProps) => {
	const { isLoading, parentRef } = props;

	const onButtonClick = useCallback(() => {
		trackDownload();

		if (!parentRef?.current) {
			return;
		}

		parentRef.current.style.padding = '32px 16px';
		downloadImage(parentRef.current);
		parentRef.current.style.padding = 'unset';
	}, [parentRef]);

	return (
		<ButtonWrapper>
			<Button
				startIcon={<Icon isLoading={isLoading} />}
				variant="contained"
				fullWidth
				disabled={isLoading}
				size="large"
				type="button"
				onClick={onButtonClick}
			>
				Download QR
			</Button>
		</ButtonWrapper>
	);
};

export default DownloadButton;
