import { useCallback, useEffect, useMemo, useState } from 'react';

import AmountInput from './AmountInput/AmountInput';
import ProviderSelect from './ProviderSelect/ProviderSelect';
import { AccountEventOptions } from '../utils/eventOptions';
import { Button, FormHelperText } from '@fhl-world/salmon-ui';
import { AmountSuggestParams } from './AmountInput/utils/suggestTypes';
import { ErrorResponse } from '../../../types/generated/pos';
import styled from '@emotion/styled';
import { flatProviders } from '../utils/flatProviders';
import { useGetProviders } from '@api/services/providers/getProviders';
import { useNavigate } from 'react-router-dom';
import { useParamsAccountId } from '../utils/useParamsAccountId';
import { qrRepaymentUrl } from '@config/routes';
import { toSentimo } from '@utils/toSentimo/toSentimo';

const WithMargin = styled.div`
	margin: 16px 0 24px;
`;

export type PaymentFormValues = {
	amount: number;
	providerId: string;
};

type PaymentFormProps = {
	initialValues: Partial<PaymentFormValues>;
	onSubmit: (values: PaymentFormValues) => void;
	eventOptions: AccountEventOptions;
	requiredPayment?: number;
	outstandingBalance?: number;
	amountSuggest?: AmountSuggestParams;
	loading?: boolean;
	serverError: ErrorResponse | null | string;
};

const PaymentForm = (props: PaymentFormProps) => {
	const {
		initialValues,
		onSubmit,
		eventOptions,
		amountSuggest,
		loading,
		serverError,
	} = props;

	const accountId = useParamsAccountId();
	const navigate = useNavigate();
	const { data: providerListResponse } = useGetProviders();

	const [providerId, setProviderId] = useState(initialValues?.providerId);
	const [amount, setAmount] = useState(initialValues?.amount);

	const [validation, setValidation] = useState({
		amount: '',
		payment: '',
		submit: '',
	});

	const isQrRepayment = useMemo(() => {
		const providers = flatProviders(providerListResponse?.providers);
		const provider = providers.find((providerItem) => {
			return providerItem.id === providerId;
		});
		return provider?.code === 'qrphpay';
	}, [providerListResponse?.providers, providerId]);

	const handleSubmit = useCallback(() => {
		if (amount === undefined || !providerId) {
			return;
		}

		if (isQrRepayment) {
			const sentimoAmount = toSentimo(amount);
			const url = qrRepaymentUrl({
				accountId,
				amount: sentimoAmount,
				providerId,
			});
			navigate(url.pathname + url.search + url.hash);
		} else {
			onSubmit({ providerId, amount });
		}
	}, [amount, providerId, isQrRepayment, accountId, onSubmit]);

	useEffect(() => {
		if (!serverError) {
			return;
		}

		// TODO нужны типы бизнесовых ошибок и тексты для них
		const errorText =
			typeof serverError === 'string' ? serverError : serverError.message;

		setValidation({ submit: errorText, amount: '', payment: '' });
	}, [serverError]);

	return (
		<div>
			<AmountInput
				validation={validation}
				value={amount}
				eventOptions={eventOptions}
				amountSuggest={amountSuggest}
				onChange={(inputAmount) => {
					setValidation({ ...validation, amount: '' });
					setAmount(inputAmount);
				}}
				disabled={loading}
			/>

			<WithMargin>
				<ProviderSelect
					providerListResponse={providerListResponse}
					eventOptions={eventOptions}
					providerId={providerId}
					validation={validation}
					onChange={(inputProviderId) => {
						setValidation({ ...validation, payment: '' });
						setProviderId(inputProviderId);
					}}
					disabled={loading}
				/>
			</WithMargin>

			<Button
				onClick={handleSubmit}
				variant="contained"
				fullWidth
				disabled={loading}
				size="large"
			>
				{isQrRepayment ? 'Generate Qr' : 'Make a payment'}
			</Button>
			{!!validation.submit && (
				<FormHelperText error>{validation.submit}</FormHelperText>
			)}
		</div>
	);
};

export default PaymentForm;
