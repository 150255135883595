import styled from '@emotion/styled';

import DateText from './components/DateText';
import { CaptionWrapper } from './components/CaptionWrapper';

const CaptionText = styled.p`
	//styleName: Desktop / Body XS Bold;
	font-size: 11px;
	font-weight: 500;
	line-height: 16px;
	margin: 0;
	text-align: center;
	white-space: pre-line;
`;

const getBaseText = (productName: string) => {
	return `This QR code is for ${productName} payment`;
};

type CaptionProps = {
	productName: string;
	userName: string;
	expirationDate?: string;
	isLoading?: boolean;
};

const Caption = (props: CaptionProps) => {
	const { productName, userName, expirationDate, isLoading } = props;

	const text =
		userName || expirationDate
			? `${getBaseText(productName)},`
			: getBaseText(productName);

	return (
		<CaptionWrapper>
			<CaptionText>{text}</CaptionText>
			{!!userName && <CaptionText>under the name {userName}</CaptionText>}
			<DateText expirationDate={expirationDate} isLoading={isLoading} />
		</CaptionWrapper>
	);
};

export default Caption;
