import { useMemo } from 'react';

import CreditAccount from './CreditAccount';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { NotFound } from '../NotFound/NotFound';
import { useParamsAccountId } from './utils/useParamsAccountId';
import { DataWithState } from '@api/models/common';
import {
	CreditLineResponse,
	AccountsResponse,
	CreditLineStateResponse,
} from '../../types/generated/pos';

interface Props {
	accountsState: DataWithState<AccountsResponse>;
	creditStatusState: DataWithState<CreditLineStateResponse>;
}

function CreditAccountPage(props: Props) {
	const { accountsState, creditStatusState } = props;
	const accountId = useParamsAccountId();

	const credit: CreditLineResponse | undefined = useMemo(() => {
		if (accountsState.data?.creditLine?.accountId === String(accountId)) {
			return accountsState.data?.creditLine;
		}
	}, [accountId, accountsState.data]);

	if (accountsState.loading || creditStatusState.loading) {
		return <LoadingScreen />;
	}

	if (
		accountsState.error ||
		creditStatusState.error ||
		!credit ||
		!creditStatusState.data
	) {
		return <NotFound />;
	}

	return (
		<CreditAccount credit={credit} creditStatus={creditStatusState.data} />
	);
}

export default CreditAccountPage;
