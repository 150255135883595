import { differenceInCalendarDays } from 'date-fns';

import {
	CreditLineStateResponse,
	CreditLineStateResponseNotPaidDueLessThan7DaysTypeEnum,
	CreditLineStateResponseNotPaidDueMissedTypeEnum,
	CreditLineStateResponseNotPaidDueMoreThan7DaysTypeEnum,
	CreditLineStateResponseNotPaidDueTodayTypeEnum,
	CreditLineStateResponseNotPaidDueTomorrowTypeEnum,
	CreditLineStateResponseWaitingStatementTypeEnum,
} from '../../../../types/generated/pos';
import { PermittedNotificationTypes } from '../Notification/config';
import { parseDate } from '@utils/date';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { getDateWithYear } from '@utils/dates/formatDates';

const formatDate = (serverDate: string): string => {
	const parsedDate = parseDate(serverDate);
	return getDateWithYear(parsedDate);
};

type CreditNotificationInfo = {
	type?: PermittedNotificationTypes;
	notification?: string;
	caption: string;
};

export const getNotificationInfo = (
	creditStatus: CreditLineStateResponse
): CreditNotificationInfo | undefined => {
	if (
		creditStatus.type ===
		CreditLineStateResponseWaitingStatementTypeEnum.WaitingStatement
	) {
		const { minPaymentCalculationDate, dueDate } = creditStatus;
		return {
			caption: `Minimum payment will be calculated on ${formatDate(
				minPaymentCalculationDate
			)}.`,
			notification: `You’ll have to pay this by ${formatDate(dueDate)}`,
			type: PermittedNotificationTypes.SUCCESS_NOTIFICATION,
		};
	}

	if (
		creditStatus.type ===
		CreditLineStateResponseNotPaidDueMoreThan7DaysTypeEnum.NotPaidDueMoreThan7Days
	) {
		const { dueDate, minPayment } = creditStatus;
		return {
			caption: `Minimum payment of ${formatMoney(
				minPayment
			)} is due on ${formatDate(dueDate)}`,
		};
	}

	if (
		creditStatus.type ===
		CreditLineStateResponseNotPaidDueLessThan7DaysTypeEnum.NotPaidDueLessThan7Days
	) {
		const { dueDate, minPayment } = creditStatus;
		const parsedDate = parseDate(dueDate);
		const daysBeforeDueDate = differenceInCalendarDays(parsedDate, new Date());
		const amount = formatMoney(minPayment);
		return {
			caption: `Minimum payment of ${amount} is due on ${formatDate(dueDate)}.`,
			notification: `${daysBeforeDueDate} days before your due date`,
			type: PermittedNotificationTypes.WARNING_NOTIFICATION,
		};
	}

	if (
		creditStatus.type ===
		CreditLineStateResponseNotPaidDueTomorrowTypeEnum.NotPaidDueTomorrow
	) {
		const { dueDate, minPayment } = creditStatus;
		const amount = formatMoney(minPayment);
		return {
			caption: `Minimum payment of ${amount} is due on ${formatDate(dueDate)}.`,
			notification: 'Your due date is tomorrow',
			type: PermittedNotificationTypes.WARNING_NOTIFICATION,
		};
	}

	if (
		creditStatus.type ===
		CreditLineStateResponseNotPaidDueTodayTypeEnum.NotPaidDueToday
	) {
		const { minPayment } = creditStatus;
		const amount = formatMoney(minPayment);
		return {
			caption: `Minimum payment of ${amount} is due today.`,
			notification: 'Your due date is today',
			type: PermittedNotificationTypes.WARNING_NOTIFICATION,
		};
	}

	if (
		creditStatus.type ===
		CreditLineStateResponseNotPaidDueMissedTypeEnum.NotPaidDueMissed
	) {
		const { minPayment } = creditStatus;
		const amount = formatMoney(minPayment);
		return {
			caption: `You’ve missed a payment. Pay ${amount} now.`,
			notification:
				'The credit line is temporarily locked. Please settle the overdue payment to continue using it',
			type: PermittedNotificationTypes.BAD_NOTIFICATION,
		};
	}
};
