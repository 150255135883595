import { useLocation } from 'react-router-dom';
import { toMoney } from './toMoney/toMoney';
import { Money } from 'src/types/generated/pos';

export const useEnsuredParams = <OUTPUT = string>(
	parameterName: string,
	fn?: (parameterValue: string) => OUTPUT
): OUTPUT => {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const value = searchParams.get(parameterName);

	if (value === null) {
		throw new Error(
			`Parameter "${parameterName}" was not passed: ${searchParams.toString()}`
		);
	}

	return fn ? fn(value) : (value as OUTPUT);
};

export const useEnsuredMoney = (parameterName: string): Money => {
	return useEnsuredParams<Money>(parameterName, (value) => {
		if (isNaN(Number(value))) {
			throw new Error(
				`Parameter "${parameterName}" is not a valid number: ${value}`
			);
		}
		return toMoney(Number(value));
	});
};
