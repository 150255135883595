import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { track } from '@amplitude/analytics-browser';

import Notification from '../Notification/Notification';
import {
	CreditLineResponse,
	CreditLineStateResponse,
} from '../../../../types/generated/pos';
import { getNotificationInfo } from './getNotificationInfo';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { getCreditEventOptions } from '../../utils/eventOptions';

const Container = styled.div`
	margin-bottom: 16px;
`;

const Text = styled.p`
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin: 0 0 16px;
`;

type CreditNotificationProps = {
	credit: CreditLineResponse;
	creditStatus: CreditLineStateResponse;
};

const CreditNotification = (props: CreditNotificationProps) => {
	const { credit, creditStatus } = props;

	const creditNotificationInfo = useMemo(() => {
		return getNotificationInfo(creditStatus);
	}, [creditStatus]);

	useEffect(() => {
		const eventOptions = getCreditEventOptions(credit);
		track(AmplitudeEvents.CreditLineMain_Opened, {
			state: creditStatus?.type,
			product: eventOptions,
		});
	}, []);

	if (!creditNotificationInfo) {
		return null;
	}

	const { caption, type, notification } = creditNotificationInfo;

	return (
		<Container>
			<Text>{caption}</Text>
			{!!type && !!notification && (
				<Notification type={type} msgCmp={notification} />
			)}
		</Container>
	);
};

export default CreditNotification;
