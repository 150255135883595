import { useEffect } from 'react';
import { usePaymentScheduleApi } from '@api/services/loanService/loanApiService';
import { type DataWithState } from '@api/models/common';
import { type ApiAccountData } from '@api/models/account';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import styled from '@emotion/styled';
import { formatSentimo } from '@utils/formatSentimo/formatSentimo';
import { ApiScheduleStatusCodes } from '@api/models/loan';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import checkMark from '@assets/icons/CheckMark.svg';
import { format, parse } from 'date-fns';
import { useParams } from 'react-router-dom';
import { getLoanEventOptions } from '../Accounts/utils/eventOptions';

interface PaymentScheduleProps {
	loanRes: DataWithState<ApiAccountData>;
}

const Title = styled.h3`
	font-size: 24px;
	line-height: 28px;
	font-weight: 800;
	color: #1b1f3bcc;
	padding: 0 0 4px;
	margin: 0;
`;

const StatusWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
	padding: 24px 20px;
	border: 1px solid #e3e2e1;
	border-radius: 24px;
`;

const StatusInfo = styled.span`
	font-weight: 800;
	font-size: 15px;
	line-height: 24px;
`;

const ItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
`;

const ItemInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ItemInfoContainer = styled.div`
	margin-right: 16px;
`;

const ItemInfoInnerContainer = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 16px;
	background-color: #f6f6f6;
`;

const ItemMonth = styled.div`
	font-size: 13px;
	line-height: 20px;
	font-weight: 800;
	text-transform: uppercase;
	color: #ea5347;
`;

const ItemDay = styled.div`
	font-size: 20px;
	line-height: 24px;
	font-weight: 800;
`;

const CurrentInner = styled.div`
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
	border-radius: 16px;
	color: #828282;
	background-color: #f6f6f6;
	padding: 3px 12px;
`;

const ItemPrice = styled.div`
	font-size: 15px;
	line-height: 24px;
	font-weight: 800;
`;

const MissedInner = styled.div`
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
	border-radius: 16px;
	color: #ea5347;
	padding: 3px 12px;
`;

const PaidInner = styled.div`
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
	border-radius: 16px;
	color: #3aa981;
	background-color: rgba(74, 201, 155, 0.32);
	padding: 3px 12px;
`;

const PaidImage = styled.img`
	margin-right: 5px;
`;

const PaymentMissed = () => <MissedInner>Missed</MissedInner>;
const PaymentCurrent = () => <CurrentInner>Up next</CurrentInner>;
const PaymentPaid = () => (
	<PaidInner>
		<PaidImage src={checkMark} alt="logo" />
		<span>Paid</span>
	</PaidInner>
);

const PaymentPending = () => <div></div>;

type StatusAmountConfig = {
	[key in keyof typeof ApiScheduleStatusCodes]: () => React.ReactNode;
};

const scheduleStatusConfig: StatusAmountConfig = {
	[ApiScheduleStatusCodes.PAID]: PaymentPaid,
	[ApiScheduleStatusCodes.MISSED]: PaymentMissed,
	[ApiScheduleStatusCodes.CURRENT]: PaymentCurrent,
	[ApiScheduleStatusCodes.PENDING]: PaymentPending,
};

function PaymentSchedule(props: PaymentScheduleProps) {
	const { loanRes } = props;
	const { loanId } = useParams();

	const loan = loanRes.data?.loans.find(
		(currLoan) => currLoan.loanId.toString() === loanId
	);
	const paymentScheduleRes = usePaymentScheduleApi(
		loan?.loanId as unknown as string
	);

	useEffect(() => {
		if (loan) {
			const eventOptions = getLoanEventOptions(loan);
			track(AmplitudeEvents.PaymentSchedule_Opened, { product: eventOptions });
		}
	}, [loan]);

	if (paymentScheduleRes.loading || loanRes.loading) {
		return <LoadingScreen />;
	}

	const renderStatus = (status: ApiScheduleStatusCodes): React.ReactNode => {
		if (!scheduleStatusConfig[status]) {
			return <div></div>;
		}
		return scheduleStatusConfig[status]();
	};

	return (
		<section>
			<Title>Payment schedule</Title>

			{paymentScheduleRes.data?.schedule.map((scheduleItem) => {
				const dt = parse(scheduleItem.dueDate, 'yyyy-MM-dd', new Date());

				return (
					<ItemContainer key={`${scheduleItem.dueDate}${scheduleItem.paid}`}>
						<ItemInner>
							<ItemInfoContainer>
								<ItemInfoInnerContainer>
									<ItemMonth>{format(dt, 'MMM')}</ItemMonth>
									<ItemDay>{format(dt, 'd')}</ItemDay>
								</ItemInfoInnerContainer>
							</ItemInfoContainer>
							<ItemPrice>{formatSentimo(scheduleItem.base)}</ItemPrice>
						</ItemInner>
						<div>{renderStatus(scheduleItem.status)}</div>
					</ItemContainer>
				);
			})}

			<StatusWrapper>
				<div>Completed payments</div>
				<StatusInfo>
					{loan?.progress.paid} of {loan?.progress.total}
				</StatusInfo>
			</StatusWrapper>
		</section>
	);
}
export default PaymentSchedule;
