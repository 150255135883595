import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Dialog, Button } from '@fhl-world/salmon-ui';

import QrRepaymentLoad from './QrRepaymentLoad';
import { useEffect } from 'react';
import { sendError } from '@utils/logger/sendLog';

const Wrapper = styled.div`
	max-width: 312px;
	padding: 24px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
`;

const Header = styled.p`
	//styleName: Android/Heading m;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	text-align: left;
	margin: 0 0 10px;
`;
const Text = styled.p`
	//styleName: Android/body l;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	text-align: left;
	margin: 0 0 48px;
`;

const GoHomeButton = styled(Button)`
	margin-left: auto;
`;
type ErrorBlockProps = {
	error: unknown;
};
const ErrorBlock = (props: ErrorBlockProps) => {
	const { error } = props;

	const navigate = useNavigate();
	const goHome = () => navigate('/');

	useEffect(() => {
		sendError({ msg: '[Browser] Qr Repayment Error', error });
	}, []);

	return (
		<>
			<QrRepaymentLoad />

			<Dialog open>
				<Wrapper>
					<Header>Something went wrong</Header>
					<Text>
						A technical issue stopped us from completing the generating. Try
						again or choose a different payment method
					</Text>
					<GoHomeButton onClick={goHome}>ok</GoHomeButton>
				</Wrapper>
			</Dialog>
		</>
	);
};

export default ErrorBlock;
