import { isError } from '../isError';

export const parseErrorStack = (error: unknown): string => {
	if (isError(error)) {
		return error.stack || error.message;
	}

	if (typeof error === 'string') {
		return error;
	}

	return JSON.stringify(error);
};
