import styled from '@emotion/styled';

import AmountTitle from './components/AmountTitle';
import CaptionLoad from './components/Caption/CaptionLoad';
import DownloadButton from './components/DownloadButton/DownloadButton';
import HowToPayHeader from './components/HowToPayHeader';
import HowToPayList from './components/HowToPayList';
import QrImage from './components/QrImage';
import { Money } from 'src/types/generated/pos';

const Container = styled.div`
	padding: 0 16px;
	position: relative;
`;

type QrRepaymentLoadProps = {
	amount?: Money;
};

const QrRepaymentLoad = (props: QrRepaymentLoadProps) => {
	const { amount } = props;

	return (
		<Container>
			<QrImage />
			<AmountTitle amount={amount} />
			<CaptionLoad />
			<HowToPayHeader />
			<HowToPayList />
			<DownloadButton isLoading />
		</Container>
	);
};

export default QrRepaymentLoad;
