export const creditLineRoute = (accountId: string | number) =>
	`/creditLine/${accountId}`;

export const financingRoute = (accountId: string | number) =>
	`/financing/${accountId}`;

export const getUrlToComeBack = (): string => {
	try {
		const currentUrl = window.location;
		const redirect = currentUrl.pathname + currentUrl.search + currentUrl.hash;
		const encodedRedirect = encodeURI(redirect);

		const urlWithRedirect = new URL(window.location.origin);
		urlWithRedirect.searchParams.append('redirectTo', encodedRedirect);

		return urlWithRedirect.toString();
	} catch {
		return window.location.origin;
	}
};

export const extractRedirectPath = (): string | undefined => {
	try {
		const currentUrl = new URL(window.location.toString());
		const encodedRedirectPath = currentUrl.searchParams.get('redirectTo');
		return encodedRedirectPath ? decodeURI(encodedRedirectPath) : undefined;
	} catch {
		return undefined;
	}
};

export enum QrRepaymentParams {
	amount = 'amount',
	providerId = 'providerId',
}
type QrRepaymentUrlParams = {
	accountId: string | number;
	amount: number;
	providerId: string;
};
export const qrRepaymentUrl = (params: QrRepaymentUrlParams): URL => {
	const url = new URL(
		`/qrRepayment/${params.accountId}`,
		window.location.origin
	);

	url.searchParams.append(QrRepaymentParams.amount, String(params.amount));
	url.searchParams.append(QrRepaymentParams.providerId, params.providerId);
	return url;
};
