import {
	AccountsResponse,
	CreditLineResponse,
	LoanResponse,
} from 'src/types/generated/pos';

type Params = {
	accountId: number;
	accounts: AccountsResponse;
};

export const getAccountById = (
	params: Params
): CreditLineResponse | LoanResponse => {
	const { accountId, accounts } = params;
	const { loans, creditLine } = accounts;

	const account = [creditLine, ...loans].filter(Boolean).find((accountItem) => {
		if ((accountItem as CreditLineResponse).accountId === String(accountId)) {
			return true;
		}
		if ((accountItem as LoanResponse).loanId === accountId) {
			return true;
		}
		return false;
	});

	if (!account) {
		throw new Error('Could not find account item by ID');
	}

	return account;
};
