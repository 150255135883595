import { format } from 'date-fns';

// Jul 1
export const getShortDate = (date: Date): string => {
	try {
		return date && format(date, 'LLL d');
	} catch {
		return '';
	}
};

// Jul 1, 2015
export const getDateWithYear = (date: Date): string => {
	try {
		return date && format(date, 'LLL d, yyyy');
	} catch {
		return '';
	}
};

export const getTime = (date: Date): string => {
	try {
		return date && format(date, 'hh:mm a');
	} catch {
		return '';
	}
};
