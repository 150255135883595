import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataWithState } from '@api/models/common';
import { creditLineRoute, financingRoute } from '@config/routes';
import { AccountsResponse } from '../../../types/generated/pos';

export const useRedirectToAccount = (
	accountsState: DataWithState<AccountsResponse>
): void => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!accountsState.data) {
			return;
		}

		if (accountsState.data.creditLine && !accountsState.data.loans) {
			navigate(creditLineRoute(accountsState.data.creditLine.accountId));
		}

		if (
			!accountsState.data.creditLine &&
			accountsState.data.loans.length === 1
		) {
			const onlyOneLoan = accountsState.data.loans[0];
			navigate(financingRoute(onlyOneLoan.loanId));
		}
	}, [accountsState.data]);
};
