import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { track } from '@amplitude/analytics-browser';
import {
	TextField,
	MenuItem,
	OutlinedInputProps,
	CdnIcon,
} from '@fhl-world/salmon-ui';

import { AmplitudeEvents } from '@utils/amplitudeEvents';
import {
	PaymentsRepaymentsProvidersListData,
	RepaymentProvider,
} from '../../../../types/generated/pos';
import ProviderItem from './ProviderItem';
import { AccountEventOptions } from '../../utils/eventOptions';
import { flatProviders } from '../../utils/flatProviders';

const PaymentIconWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
	margin: -10px 0 -16px 0;
`;

const PaymentOptionText = styled.div`
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
	color: rgba(27, 31, 59, 0.65);
`;

const OptionTypeText = styled.span`
	font-size: 13px;
	line-height: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.54);
	margin: 0 16px;
`;

const filterProviderList = (
	providerList: PaymentsRepaymentsProvidersListData['providers']
) => {
	return providerList?.filter(
		(
			providerInfo
		): providerInfo is Required<
			Required<PaymentsRepaymentsProvidersListData>['providers'][number]
		> => {
			return !!providerInfo.providers && !!providerInfo.type;
		}
	);
};

type ProviderSelectProps = {
	onChange: (providerId: string) => void;
	providerId: string | undefined;
	validation: { amount: string; payment: string };
	eventOptions: AccountEventOptions;
	disabled?: boolean;
	providerListResponse: PaymentsRepaymentsProvidersListData | null;
};

const ProviderSelect = (props: ProviderSelectProps) => {
	const {
		onChange,
		providerId,
		validation,
		eventOptions,
		disabled,
		providerListResponse,
	} = props;

	const providerItems: RepaymentProvider[] = useMemo(() => {
		return flatProviders(providerListResponse?.providers);
	}, [providerListResponse?.providers]);

	const filteredProviderList = useMemo(() => {
		return filterProviderList(providerListResponse?.providers);
	}, [providerListResponse?.providers]);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const inputProviderId = event.target.value;

			onChange?.(inputProviderId);

			providerItems.forEach((provider) => {
				if (provider.id === inputProviderId) {
					track(AmplitudeEvents.PaymentOptionSelected_Tap, {
						payment_channel: provider.code,
						product: eventOptions,
					});
				}
			});
		},
		[onChange, providerItems, eventOptions]
	);

	return (
		<TextField
			label={providerId ? '' : 'Payment option'}
			aria-label={'payment-option'}
			error={validation.payment.length > 0}
			helperText={validation.payment}
			select
			onBlur={() => {
				track(AmplitudeEvents.PaymentOption_Tap, { product: eventOptions });
			}}
			value={providerId || ''}
			onChange={handleChange}
			hiddenLabel={providerId !== null}
			variant="filled"
			fullWidth
			InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
			disabled={disabled || !filterProviderList}
			SelectProps={{
				renderValue: (value) => {
					const selectedProvider = providerItems.find((provider) => {
						return value === provider.id;
					});

					if (!selectedProvider) return <></>;

					return (
						<PaymentIconWrapper>
							<CdnIcon code={selectedProvider.code} width={32} height={32} />
							<div>
								<PaymentOptionText>Payment option</PaymentOptionText>
								<div>{selectedProvider.name}</div>
							</div>
						</PaymentIconWrapper>
					);
				},
			}}
		>
			{filteredProviderList?.map((providerInfo) => {
				const { providers, type } = providerInfo;

				const list = providers.map((provider) => {
					return (
						<MenuItem value={provider.id} key={provider.id}>
							<ProviderItem provider={provider} />
						</MenuItem>
					);
				});

				return [
					<div key={type}>
						<OptionTypeText>{type}</OptionTypeText>
					</div>,
					list,
				];
			}) || <div></div>}
		</TextField>
	);
};

export default ProviderSelect;
