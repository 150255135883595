import { getAccountById } from './getAccountById';
import {
	LoanResponse,
	LoanResponseLoanTypeEnum,
} from 'src/types/generated/pos';

export const getAccountName = (
	params: Parameters<typeof getAccountById>[0]
): 'Salmon Credit' | 'Financing' | 'Cash Loan' => {
	const account = getAccountById(params);

	if ((account as LoanResponse).loanType === LoanResponseLoanTypeEnum.CASH) {
		return 'Cash Loan';
	} else if (
		(account as LoanResponse).loanType === LoanResponseLoanTypeEnum.POS
	) {
		return 'Financing';
	} else {
		return 'Salmon Credit';
	}
};
