import styled from '@emotion/styled';

export const PaymentTitle = styled.h4`
	font-size: 20px;
	line-height: 24px;
	font-weight: 800;
	font-style: normal;
	margin: 0 0 8px;
	padding: 0;
`;
