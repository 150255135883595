import DateText from './components/DateText';
import { Skeleton } from '@fhl-world/salmon-ui';
import { CaptionWrapper } from './components/CaptionWrapper';

const CaptionLoad = () => {
	return (
		<CaptionWrapper>
			<Skeleton
				width={'60%'}
				variant="text"
				sx={{ fontSize: '11px', lineHeight: '16px' }}
			/>
			<Skeleton
				width={'75%'}
				variant="text"
				sx={{ fontSize: '11px', lineHeight: '16px' }}
			/>
			<DateText isLoading />
		</CaptionWrapper>
	);
};

export default CaptionLoad;
