import { ReactComponent as ArrowForwardIosIcon } from '@assets/icons/ArrowForwardIos.svg';
import styled from '@emotion/styled';

const Container = styled.section`
	background-color: #f6f6f6;
	border-radius: 12px;
	padding: 16px;
	margin-top: 32px;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
`;

const Title = styled.h3`
	font-size: 20px;
	line-height: 24px;
	font-weight: 800;
	font-style: normal;
	padding: 12px 0;
	margin: 0;
`;

const PaymentContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;
`;

const CompletedResult = styled.span`
	font-weight: bold;
`;

const InnerContainer = styled(PaymentContainer)`
	cursor: pointer;
`;

type OverviewOptionText = { label: string; text: string };
type OverviewOptionLink = { label: string; onClick: () => void };
export type OverviewOption = OverviewOptionText | OverviewOptionLink;

const isTextOption = (option: OverviewOption): option is OverviewOptionText => {
	return !!(option as OverviewOptionText).text;
};

const LinkOption = (props: OverviewOptionLink) => {
	const { label, onClick } = props;
	return (
		<InnerContainer onClick={onClick}>
			<span>{label}</span>
			<div>
				{/*TODO: replace import to @fhl-world/salmon-ui when all icons will be exported */}
				<ArrowForwardIosIcon />
			</div>
		</InnerContainer>
	);
};

const TextOption = (props: OverviewOptionText) => {
	const { label, text } = props;
	return (
		<PaymentContainer>
			<span>{label}</span>
			<CompletedResult>{text}</CompletedResult>
		</PaymentContainer>
	);
};

type LoanOverviewProps = {
	options: OverviewOption[];
};

function LoanOverview(props: LoanOverviewProps) {
	const { options } = props;

	const optionList = options.map((option) => {
		return isTextOption(option) ? (
			<TextOption {...option} key={option.label} />
		) : (
			<LinkOption {...option} key={option.label} />
		);
	});

	return (
		<Container>
			<Title>Overview</Title>
			{optionList}
		</Container>
	);
}

export default LoanOverview;
