import {
	CreditLineContractListData,
	GetContractData,
} from '../../../types/generated/pos';
import { BASE_URL } from '@utils/common';
import { useFetch } from '@api/services/utilsService/utilsApiService';

export const useGetCreditAgreementApi = () => {
	return useFetch<CreditLineContractListData>(
		`${BASE_URL}/v1/credit-line/contract`
	);
};

export const useGetLoanAgreementApi = (accountId: number) => {
	return useFetch<GetContractData>(`${BASE_URL}/v1/loan/${accountId}/contract`);
};
