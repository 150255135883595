import styled from '@emotion/styled';

import { ReactComponent as QrPhLogo } from '@assets/icons/QrPhLogo.svg';

const HowToPayContainer = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-gap: 8px;
	align-items: center;
	margin-bottom: 16px;
`;
const HowToPayTitle = styled.h6`
	//styleName: Desktop/Heading 6;
	font-size: 20px;
	font-weight: 800;
	line-height: 24px;
	margin: 0;
`;

const HowToPayHeader = () => {
	return (
		<HowToPayContainer>
			<HowToPayTitle>How to pay with</HowToPayTitle>
			<QrPhLogo />
		</HowToPayContainer>
	);
};
export default HowToPayHeader;
