import styled from '@emotion/styled';
import { CircularProgress } from '@fhl-world/salmon-ui';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const InnerContainer = styled.div`
	margin-top: 32px;
`;

function LoadingScreen() {
	return (
		<Container>
			<InnerContainer>
				<CircularProgress />
			</InnerContainer>
		</Container>
	);
}

export default LoadingScreen;
