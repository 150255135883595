import { parseErrorStack } from './utils/parseErrorStack';
import { sendLogEvent } from './utils/sendLogEvent';

type LogEvent = {
	msg: string;
	error?: unknown;
};

export const sendError = async (logEvent: Required<LogEvent>) => {
	const error = parseErrorStack(logEvent.error);

	const event = { ...logEvent, error, level: 'error' } as const;

	return sendLogEvent(event).catch(() => null);
};

export const sendInfo = async (logEvent: LogEvent) => {
	const error: string | undefined = logEvent.error
		? parseErrorStack(logEvent.error)
		: undefined;

	const event = { ...logEvent, error, level: 'info' } as const;

	return sendLogEvent(event).catch(() => null);
};
