import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, theme } from '@fhl-world/salmon-ui';
import AuthProvider from './components/AuthProvider';
import { handleBrowserError } from '@utils/logger/handleBrowserError';
import { configureSentry } from './sentry';

configureSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

handleBrowserError();

root.render(
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ThemeProvider>
	</BrowserRouter>
);

reportWebVitals();
