import { useNavigate } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

import AccountOverview, { OverviewOption } from './AccountOverview';
import { CreditLineResponse } from '../../../types/generated/pos';
import { parseDate } from '@utils/date';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { useGetCreditAgreementApi } from '@api/services/accountService/useGetAgreementApi';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { getCreditEventOptions } from '../utils/eventOptions';
import { getDateWithYear } from '@utils/dates/formatDates';

type CreditOverviewProps = {
	credit: CreditLineResponse;
};

const CreditOverview = (props: CreditOverviewProps) => {
	const { credit } = props;

	const agreementState = useGetCreditAgreementApi();

	const parsedDueDate = credit.dueDate && parseDate(credit.dueDate);
	const formattedDueDate = parsedDueDate && getDateWithYear(parsedDueDate);

	const navigate = useNavigate();

	const transactions = {
		label: 'Transactions',
		onClick: () => {
			// TODO различаются события аналитики для транзакций кредита и кэшлоана
			track(AmplitudeEvents.TransactionHistory_Tap, {
				type: 'CreditLine',
			});
			navigate(`/history/${credit.accountId}`);
		},
	};

	const outstanding = {
		label: 'Outstanding balance',
		text: formatMoney(credit.balance.outstanding),
	};

	const dueDate = formattedDueDate
		? { label: 'Due date', text: formattedDueDate }
		: null;

	const limit = {
		label: 'Credit limit',
		text: formatMoney(credit.balance.available),
	};

	const agreement = {
		label: 'Salmon Credit Agreement',
		onClick: () => {
			if (!agreementState.data?.location) {
				return;
			}
			track(AmplitudeEvents.Amount_Tap, {
				product: getCreditEventOptions(credit),
				source: 'CreditlineMain',
			});
			window.open(agreementState.data.location, '_blank');
		},
	};

	const options = [transactions, outstanding, dueDate, limit, agreement].filter(
		Boolean
	) as OverviewOption[];

	return <AccountOverview options={options} />;
};

export default CreditOverview;
