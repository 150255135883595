import styled from '@emotion/styled';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { Money } from '../../../types/generated/pos';

const Caption = styled.p`
	font-size: 17px;
	font-style: normal;
	font-weight: 800;
	line-height: 28px;
	margin: 0 0 2px;
`;
const Header = styled.p`
	font-size: 36px;
	font-style: normal;
	font-weight: 800;
	line-height: 40px;
	margin: 0 0 32px;
`;

type CreditPaymentInfoProps = {
	availableBalance: Money;
};

const CreditPaymentInfo = (props: CreditPaymentInfoProps) => {
	const { availableBalance } = props;

	return (
		<>
			<Caption>Available balance</Caption>
			<Header>{formatMoney(availableBalance)}</Header>
		</>
	);
};

export default CreditPaymentInfo;
