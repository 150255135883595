import styled from '@emotion/styled';
import { Skeleton } from '@fhl-world/salmon-ui';

const Container = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 0 16px;
`;
const Image = styled.img`
	width: 200px;
	height: 200px;
`;

type QrImageProps = {
	src?: string;
};

const QrImage = (props: QrImageProps) => {
	const { src } = props;

	return (
		<Container>
			{src ? (
				<Image src={src} crossOrigin="anonymous" />
			) : (
				<Skeleton width={200} height={200} variant="rounded" />
			)}
		</Container>
	);
};

export default QrImage;
