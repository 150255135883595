import { init } from '@sentry/react';
import { SENTRY_DSN } from '@utils/common';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { isProd } from '@config/envVariables';

export function configureSentry() {
	init({
		dsn: SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: true,
			}),
			Sentry.browserApiErrorsIntegration({
				setTimeout: true,
				setInterval: true,
				requestAnimationFrame: true,
				XMLHttpRequest: true,
				eventTarget: true,
			}),
		],
		enabled: isProd,
		tracesSampleRate: 0.05,
		debug: false,
		replaysOnErrorSampleRate: 0.1,
		replaysSessionSampleRate: 0.01,
	});
}
