import { useState } from 'react';
import SalmonLogo from '@assets/icons/SalmonLogo.svg';
import {
	AppBar as UiKitAppBar,
	Avatar,
	IconButton,
	Menu,
	MenuItem,
} from '@fhl-world/salmon-ui';
import { Link, useNavigate } from 'react-router-dom';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { track } from '@amplitude/analytics-browser';
import { socialMediaLinks } from '@config/socialMediaLinks';
import styled from '@emotion/styled';

const onPageEnum = {
	clientMain: 'client-main',
	clientProfile: 'client-profile',
	clientOrder: 'client-order',
	clientHistory: 'client-history',
	clientSchedule: 'client-schedule',
	clientPaymentSuccess: 'client-payment-success',
	clientPaymentFailed: 'payment-failed',
};

const Container = styled(UiKitAppBar)`
	height: 56px;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
`;

const InnerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	max-width: 404px;
	margin: auto;
	padding: 0 12px;
	box-sizing: border-box;
`;

const Icon = styled.img`
	position: relative;
	width: 100%;
	height: 100%;
`;

const LinkWrapper = styled.div`
	flex-grow: 0;
`;

const InfoWrapper = styled.div`
	display: flex;
`;

const MenuWrapper = styled.div`
	width: 240px;
`;

const IconButtonExtended = styled(IconButton)`
	height: 100%;
`;

const MainAppBar = (props: { userName: string }) => {
	const { userName } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { facebookChat: facebookChatConfig } = socialMediaLinks;

	const onWhatPageTap = (amplEvent: AmplitudeEvents) => {
		let onPage: string;

		if (window.location.pathname.startsWith('/profile')) {
			onPage = onPageEnum.clientProfile;
		} else if (window.location.pathname.startsWith('/order')) {
			onPage = onPageEnum.clientOrder;
		} else if (window.location.pathname.startsWith('/history')) {
			onPage = onPageEnum.clientHistory;
		} else if (window.location.pathname.startsWith('/schedule')) {
			onPage = onPageEnum.clientSchedule;
		} else if (window.location.pathname.startsWith('/payment_success')) {
			onPage = onPageEnum.clientPaymentSuccess;
		} else if (window.location.pathname.startsWith('/payment_error')) {
			onPage = onPageEnum.clientPaymentFailed;
		} else {
			onPage = onPageEnum.clientMain;
		}

		track(amplEvent, { on_page: onPage });
	};
	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const navigate = useNavigate();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const userNameWithoutExtraSpaces = userName
		? userName.replace(/\s+/g, ' ').trim()
		: '';
	const nameForLogo = userNameWithoutExtraSpaces
		? `${userNameWithoutExtraSpaces.split(' ')[0][0]}${
				userNameWithoutExtraSpaces.split(' ')[1][0]
		  }`
		: ''; // todo check by ?

	return (
		<Container color={'transparent'} position="static">
			<InnerContainer>
				<LinkWrapper>
					<Link
						onClick={() => onWhatPageTap(AmplitudeEvents.Salmon_Tap)}
						to={'/'}
					>
						<Icon src={SalmonLogo} alt="logo" />
					</Link>
				</LinkWrapper>
				<InfoWrapper>
					<Link
						to={facebookChatConfig.url}
						target="_blank"
						aria-label={facebookChatConfig.name}
						onClick={() => track(AmplitudeEvents.Chat_Tap)}
					>
						<IconButtonExtended size="large" color="inherit">
							<Icon
								src={facebookChatConfig.icon}
								alt={facebookChatConfig.name}
							/>
						</IconButtonExtended>
					</Link>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						onClick={handleMenu}
						color="inherit"
					>
						<Avatar
							sx={{
								bgcolor: '#1B1F3BA6',
								fontSize: '13px',
								width: '32px',
								height: '32px',
							}}
						>
							{nameForLogo}
						</Avatar>
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuWrapper>
							<MenuItem
								onClick={() => {
									navigate('/profile');
									handleClose();
								}}
							>
								Profile
							</MenuItem>
						</MenuWrapper>
					</Menu>
				</InfoWrapper>
			</InnerContainer>
		</Container>
	);
};

export default MainAppBar;
