import type { ErrorResponse } from '../../types/generated/pos';

const unknownError = (message = 'Something went wrong.'): ErrorResponse => {
	return { message, errorDetails: {}, errorType: 'UNKNOWN' };
};

export const normalizeError = (error: unknown): ErrorResponse => {
	if (!error) {
		return unknownError();
	}

	if (typeof error === 'string') {
		return unknownError(error);
	}

	if (typeof error !== 'object') {
		return unknownError();
	}

	if ('message' in error) {
		return error as ErrorResponse;
	}

	return unknownError();
};
