import { useCallback, useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';

import CreditNotification from './components/CreditNotification/CreditNotification';
import CreditPaymentInfo from './components/CreditPaymentInfo';
import CreditOverview from './components/CreditOverview';
import PaymentForm, { PaymentFormValues } from './components/PaymentForm';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import {
	MoneyCurrencyEnum,
	CreditLineResponse,
	RepaymentPaymentAccountAccountTypeEnum,
	CreditLineStateResponse,
} from '../../types/generated/pos';
import { usePostPaymentApi } from '@api/services/accountService/usePostPaymentApi';
import { getCreditEventOptions } from './utils/eventOptions';
import { getCreditMinPayment } from './utils/getCreditMinPayment';
import { AmountSuggestParams } from './components/AmountInput/utils/suggestTypes';
import { useSuccessRedirect } from './utils/useSuccessRedirect';
import { PaymentTitle } from './components/PaymentTitle';
import { Caption } from './components/Caption';

interface Props {
	credit: CreditLineResponse;
	creditStatus: CreditLineStateResponse;
}

function CreditAccount(props: Props) {
	const { credit, creditStatus } = props;

	const [{ data: paymentData, error: paymentError, loading }, mutate] =
		usePostPaymentApi();

	const eventOptions = getCreditEventOptions(credit, creditStatus);

	const initialValues: Partial<PaymentFormValues> = useMemo(() => {
		return {
			amount: getCreditMinPayment(creditStatus)?.amount,
			providerId: undefined,
		};
	}, [creditStatus]);

	const amountSuggest: AmountSuggestParams = useMemo(() => {
		return {
			requiredPayment: getCreditMinPayment(creditStatus),
			outstandingBalance: credit.balance.outstanding,
		};
	}, [creditStatus, credit.balance.outstanding]);

	const handleSubmit = useCallback(
		({ providerId, amount }: PaymentFormValues) => {
			track(AmplitudeEvents.GoToPayment_Tap, { product: eventOptions });

			mutate({
				providerId,
				amount: { amount, currency: MoneyCurrencyEnum.PHP },
				from: {
					accountId: credit.accountId,
					accountType: RepaymentPaymentAccountAccountTypeEnum.CREDIT_LINE,
				},
			});
		},
		[eventOptions]
	);

	useSuccessRedirect(paymentData?.location);

	return (
		<div>
			<Caption>Salmon Credit</Caption>

			<CreditPaymentInfo availableBalance={credit.balance.available} />

			<PaymentTitle>Payment</PaymentTitle>
			<CreditNotification credit={credit} creditStatus={creditStatus} />

			<PaymentForm
				onSubmit={handleSubmit}
				initialValues={initialValues}
				eventOptions={eventOptions}
				amountSuggest={amountSuggest}
				serverError={paymentError}
				loading={loading}
			/>

			<CreditOverview credit={credit} />
		</div>
	);
}

export default CreditAccount;
