import styled from '@emotion/styled';

import { RepaymentProvider } from '../../../../types/generated/pos';
import { CdnIcon } from '@fhl-world/salmon-ui';

const ItemContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 4px;
`;

const ItemNameContainer = styled.div`
	display: flex;
	align-items: start;
	flex-direction: column;
	width: 100%;
	margin: 4px 0 4px 12px;
`;

const ItemName = styled.span`
	display: block;
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
`;

const ItemFee = styled.span`
	display: block;
	font-size: 13px;
	line-height: 16px;
	font-weight: 500;
	color: rgba(27, 31, 59, 0.65);
`;

type ProviderItemProps = {
	provider: RepaymentProvider;
};

const ProviderItem = (props: ProviderItemProps) => {
	const { provider } = props;

	return (
		<ItemContainer>
			<CdnIcon code={provider.code} width={32} height={32} />
			<ItemNameContainer>
				<ItemName>{provider.name}</ItemName>
				<ItemFee>{provider.fee?.amount || 'No fee'}</ItemFee>
			</ItemNameContainer>
		</ItemContainer>
	);
};

export default ProviderItem;
