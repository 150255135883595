import { PaymentNotificationStatesRBB } from '@api/models/notificationStates';

export enum PaymentCaption {
	UPCOMING = 'Upcoming payment',
	NEXT_PAYMENT = 'Next payment',
	TODAYS_PAYMENT = "Today's payment",
	MISSED_PAYMENT = 'Missed payment',
	URGENT_PAYMENT = 'Urgent! You need to pay',
	CLOSED = 'Financial product closed', // technical field, not used in UI
}

export const PaymentCaptionByPaymentState: {
	[key in PaymentNotificationStatesRBB]: PaymentCaption;
} = {
	[PaymentNotificationStatesRBB.New]: PaymentCaption.UPCOMING,
	[PaymentNotificationStatesRBB.UpcomingNotPaid]: PaymentCaption.UPCOMING,
	[PaymentNotificationStatesRBB.UpcomingPartialPaid]: PaymentCaption.UPCOMING,
	[PaymentNotificationStatesRBB.Today]: PaymentCaption.TODAYS_PAYMENT,
	[PaymentNotificationStatesRBB.TodayPartialPaid]:
		PaymentCaption.TODAYS_PAYMENT,
	[PaymentNotificationStatesRBB.PartialPaid]: PaymentCaption.UPCOMING,
	[PaymentNotificationStatesRBB.NoMissedPayments]: PaymentCaption.UPCOMING,
	[PaymentNotificationStatesRBB.PaidFull]: PaymentCaption.NEXT_PAYMENT,
	[PaymentNotificationStatesRBB.PaidMore]: PaymentCaption.NEXT_PAYMENT,
	[PaymentNotificationStatesRBB.MissedPayment]: PaymentCaption.MISSED_PAYMENT,
	[PaymentNotificationStatesRBB.MissedPaymentAndFee]:
		PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStatesRBB.MissedPaymentAndFeeUpcoming]:
		PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStatesRBB.MissedFee]: PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStatesRBB.MissedFeeUpcoming]:
		PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStatesRBB.LoanClosed]: PaymentCaption.CLOSED, // not used
};
