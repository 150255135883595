export enum AmplitudeEvents {
	POSMain_Opened = 'POSMain_Opened',
	CashloanMain_Opened = 'CashloanMain_Opened',
	PaymentSchedule_Tap = 'PaymentSchedule_Tap',
	PaymentHistory_Tap = 'PaymentHistory_Tap',
	Order_Tap = 'Order_Tap',
	GoToPayment_Tap = 'GoToPayment_Tap',
	Amount_Tap = 'Amount_Tap',
	PaymentOption_Tap = 'PaymentOption_Tap',
	PaymentOptionSelected_Tap = 'PaymentOptionSelected_Tap',
	Profile_Opened = 'Profile_Opened',
	LogOut_Tap = 'LogOut_Tap',
	LoanOverview_Tap = 'LoanOverview_Tap',
	Redirect_Opened = 'Redirect_Opened',
	PaymentSuccess_Opened = 'PaymentSuccess_Opened',
	PaymentError_Opened = 'PaymentError_Opened',
	PaymentSchedule_Opened = 'PaymentSchedule_Opened',
	OrderDetails_Opened = 'OrderDetails_Opened',
	PaymentHistory_Opened = 'PaymentHistory_Opened',
	Chat_Tap = 'Chat_Tap',
	Salmon_Tap = 'Salmon_Tap',
	GetTheApp_Tap = 'GetTheApp_Tap',
	Home_Opened = 'Home_Opened',
	Product_Tap = 'Product_Tap',
	CreditLineMain_Opened = 'CreditLineMain_Opened',
	PaymentOptions_Tap = 'PaymentOptions_Tap',
	Agreement_Tap = 'Agreement_Tap',
	TransactionHistory_Tap = 'TransactionHistory_Tap',

	// QR Repayment
	QRPh_Opened = 'QRPh_Opened',
	Download_Tap = 'Download_Tap',
}
