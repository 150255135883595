import { useMutate } from '@api/services/utilsService/utilsApiService';
import {
	PerformRepaymentResponse,
	RepaymentRequest,
} from '../../../types/generated/pos';
import { BASE_URL } from '@utils/common';

export const usePostPaymentApi = () => {
	return useMutate<PerformRepaymentResponse, RepaymentRequest>(
		`${BASE_URL}/v1/payments/repayments`
	);
};
