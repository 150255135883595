import { useFetch } from '@api/services/utilsService/utilsApiService';
import { BASE_URL } from '@utils/common';
import {
	PerformRepaymentResponse,
	RepaymentRequest,
} from 'src/types/generated/pos';

export const useGetQrRepayment = (params: RepaymentRequest | null) => {
	return useFetch<PerformRepaymentResponse>(
		params ? `${BASE_URL}/v1/payments/repayments` : null,
		{
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
};
