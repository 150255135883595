import Facebook from '@assets/icons/Facebook.svg';
import { staticURLs } from './staticURLs';
import CommentIcon from '@assets/icons/TuiIconCommentLarge.svg';
import { MediaLinks } from '@api/models/common';

export const socialMediaLinks: MediaLinks = {
	facebook: {
		name: 'Facebook',
		platform: 'facebook',
		url: staticURLs.facebook,
		icon: Facebook,
	},
	facebookChat: {
		name: 'FacebookChat',
		platform: '',
		url: staticURLs.facebookChat,
		icon: CommentIcon,
	},
};
