import { useEffect } from 'react';
import { Button } from '@fhl-world/salmon-ui';
import styled from '@emotion/styled';
import { LogoutOptions } from '@auth0/auth0-react';
import { track } from '@amplitude/analytics-browser';
import { UserData } from '@api/models/user';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { getUrlToComeBack } from '@config/routes';
import ProfileElement from './ProfileElement';

interface ProfileData {
	profileData: UserData;
	onLogout: (options?: LogoutOptions) => void;
}

const Title = styled.h3`
	font-size: 24px;
	font-weight: 800;
	line-height: 28px;
	margin: 0;
`;

const LogOutButtonWrapper = styled.div`
	padding-top: 72px;
`;

const LogOutButton = styled(Button)`
	font-size: 15px;
	font-weight: 800;
	line-height: 24px;
	text-transform: none;
`;

function Profile(props: ProfileData) {
	const { email, fullName, phone } = props.profileData;

	useEffect(() => {
		track(AmplitudeEvents.Profile_Opened);
	}, []);

	return (
		<div>
			<Title>Profile</Title>
			<ProfileElement caption={'Full name'} data={fullName} />
			<ProfileElement caption={'Telephone number'} data={phone} />
			<ProfileElement caption={'E-mail'} data={email} />

			<LogOutButtonWrapper>
				<LogOutButton
					onClick={() => {
						track(AmplitudeEvents.LogOut_Tap);
						props.onLogout({ logoutParams: { returnTo: getUrlToComeBack() } });
					}}
				>
					Log out
				</LogOutButton>
			</LogOutButtonWrapper>
		</div>
	);
}

export default Profile;
