import { useState, useEffect, useRef } from 'react';
import { PopoverOrigin } from '@fhl-world/salmon-ui';

type PopoverPosition = {
	anchorOrigin: PopoverOrigin;
	transformOrigin?: PopoverOrigin;
};

const basePosition: PopoverPosition = {
	anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
};

const aboveInputPosition: PopoverPosition = {
	anchorOrigin: { vertical: 'top', horizontal: 'left' },
	transformOrigin: { vertical: 'bottom', horizontal: 'left' },
};

const HEIGHT_BETWEEN_INPUT_AND_SUGGESTION = 12;

export const useGetPopoverPosition = (anchorEl: HTMLElement | null) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const suggestRef = useRef<HTMLDivElement>(null);

	const [position, setPosition] = useState<PopoverPosition>(basePosition);

	useEffect(() => {
		if (!anchorEl) {
			setPosition(basePosition);
			return;
		}

		if (!inputRef.current || !suggestRef.current) {
			return;
		}

		const topOfSuggest =
			suggestRef.current.getBoundingClientRect().top +
			HEIGHT_BETWEEN_INPUT_AND_SUGGESTION;

		const bottomOfInput = inputRef.current.getBoundingClientRect().bottom;

		if (topOfSuggest < bottomOfInput) {
			setPosition(aboveInputPosition);
		} else {
			setPosition(basePosition);
		}
	}, [!!anchorEl]);

	return { inputRef, suggestRef, position };
};
