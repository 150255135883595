import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

import { AUTH_URL, AUTH_CLIENT_ID, AUDIENCE_URL } from '@utils/common';

const AuthProvider = (props: { children: ReactNode }) => {
	const navigate = useNavigate();

	const handleRedirect = useCallback((appState: AppState | undefined) => {
		if (!appState?.returnTo) {
			navigate('/');
			return;
		}

		try {
			const url = new URL(appState.returnTo);
			navigate(url.pathname + url.search + url.hash);
		} catch {
			navigate('/');
		}
	}, []);

	return (
		<Auth0Provider
			domain={AUTH_URL}
			clientId={AUTH_CLIENT_ID}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: AUDIENCE_URL,
				scope: 'offline_access pos-client pos-client-unverified',
			}}
			useRefreshTokens={true}
			cacheLocation="localstorage"
			onRedirectCallback={handleRedirect}
		>
			{props.children}
		</Auth0Provider>
	);
};

export default AuthProvider;
