import styled from '@emotion/styled';
import { parseISO, format } from 'date-fns';
import { Skeleton } from '@fhl-world/salmon-ui';

// Expires Nov 21 at 9:42 pm
const formatExpirationDate = (expirationDate: string): string => {
	const date = parseISO(expirationDate);
	return `Expires ${format(date, 'LLL d')} at ${format(date, 'hh:mm a')}`;
};

export const CaptionBoldText = styled.p`
	//styleName: Desktop/Body XS;
	font-size: 11px;
	font-weight: 800;
	line-height: 16px;
	text-align: center;
	margin: 0;
`;

export type CaptionProps = {
	expirationDate?: string;
	isLoading?: boolean;
};

const DateText = (props: CaptionProps) => {
	const { expirationDate, isLoading } = props;

	const isEmptyDate = !isLoading && !expirationDate;

	if (isEmptyDate) {
		return null;
	}

	return expirationDate ? (
		<CaptionBoldText>{formatExpirationDate(expirationDate)}</CaptionBoldText>
	) : (
		<Skeleton
			width={'128px'}
			variant="text"
			sx={{ fontSize: '11px', lineHeight: '16px' }}
		/>
	);
};

export default DateText;
