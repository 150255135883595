import html2canvas from 'html2canvas';
import { getFileName } from './getFileName';

export const downloadImage = (element: HTMLDivElement) => {
	try {
		html2canvas(element, { useCORS: true, scale: 1 }).then((canvas) => {
			const link = document.createElement('a');
			link.download = `${getFileName()}.png`;
			link.href = canvas.toDataURL('image/png');
			link.click();
		});
	} catch {
		console.error(new Error('Failed to download QR'));
	}
};
