import { useEffect, useRef, useState } from 'react';

export const useGetCachedWidth = () => {
	const [width, setWidth] = useState<number>();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!ref.current || width) {
			return;
		}

		setWidth(ref.current.getBoundingClientRect().width);
	}, [ref.current]);

	return { ref, width };
};
