export interface JwtPayload {
	key: string;
	iid?: string;
}
export const parseJwt = (token: string): JwtPayload => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return JSON.parse(jsonPayload);
};
