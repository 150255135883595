import {
	forwardRef,
	useCallback,
	useState,
	MouseEvent,
	useEffect,
} from 'react';
import { track } from '@amplitude/analytics-browser';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import AmountSuggest from './AmountSuggest/AmountSuggest';
import {
	Popover,
	TextField,
	InputAdornment,
	OutlinedInputProps,
} from '@fhl-world/salmon-ui';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { AccountEventOptions } from '../../utils/eventOptions';
import { AmountSuggestParams } from './utils/suggestTypes';
import { PESO } from '@config/constants';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { useGetCachedWidth } from './utils/useGetCachedWidth';
import { Money } from '../../../../types/generated/pos';
import { useGetPopoverPosition } from './utils/usePopoverPosition';

interface NumericProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, NumericProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, ...other } = props;

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({ target: { name: props.name, value: values.value } });
				}}
				decimalScale={2}
				fixedDecimalScale
				thousandSeparator={','}
				valueIsNumericString
			/>
		);
	}
);

type AmountInputProps = {
	validation: { amount: string; payment: string };
	onChange: (amount: number) => void;
	eventOptions: AccountEventOptions;
	value: number | undefined; // PESO!
	amountSuggest?: AmountSuggestParams;
	disabled?: boolean;
};

const AmountInput = (props: AmountInputProps) => {
	const { validation, onChange, eventOptions, value, disabled } = props;

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { ref, width: popoverWidth } = useGetCachedWidth();

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleSuggestClick = useCallback(
		(suggestValue: Money) => {
			handleClose();
			onChange(suggestValue.amount);
		},
		[handleClose, onChange]
	);

	const handleInputClick = useCallback(
		(event: MouseEvent<HTMLInputElement>) => {
			setAnchorEl(event.currentTarget);
		},
		[]
	);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			handleClose();
			onChange(parseFloat(event.target.value));
		},
		[onChange, handleClose]
	);

	useEffect(() => {
		if (ref.current) setAnchorEl(ref.current);
	}, []);

	const {
		inputRef,
		suggestRef,
		position: popoverPosition,
	} = useGetPopoverPosition(anchorEl);

	return (
		<>
			<div ref={ref}>
				<TextField
					ref={inputRef}
					autoFocus={true}
					label="Amount"
					variant="filled"
					error={validation.amount.length > 0}
					helperText={validation.amount}
					value={value}
					disabled={disabled}
					onBlur={() => {
						track(AmplitudeEvents.Amount_Tap, { product: eventOptions });
					}}
					onClick={handleInputClick}
					onChange={handleChange}
					fullWidth
					InputProps={
						{
							inputMode: 'numeric',
							disableUnderline: true,
							startAdornment: (
								<InputAdornment position="start">{PESO}</InputAdornment>
							),
							inputComponent: NumericFormatCustom as unknown,
						} as Partial<OutlinedInputProps>
					}
				/>
			</div>

			{(!!props.amountSuggest?.requiredPayment ||
				!!props.amountSuggest?.outstandingBalance) && (
				<Popover
					disablePortal={true}
					disableAutoFocus={true}
					disableEnforceFocus={true}
					open={!!anchorEl}
					onClose={handleClose}
					anchorEl={anchorEl}
					{...popoverPosition}
					slotProps={{
						paper: {
							sx: { width: popoverWidth ? `${popoverWidth}px` : '100%' },
							ref: suggestRef,
						},
					}}
				>
					{!!props.amountSuggest?.requiredPayment && (
						<AmountSuggest
							type={'required'}
							label={formatMoney(props.amountSuggest?.requiredPayment)}
							value={props.amountSuggest?.requiredPayment}
							onClick={handleSuggestClick}
						/>
					)}
					{!!props.amountSuggest?.outstandingBalance && (
						<AmountSuggest
							type={'outstanding'}
							label={formatMoney(props.amountSuggest?.outstandingBalance)}
							value={props.amountSuggest?.outstandingBalance}
							onClick={handleSuggestClick}
						/>
					)}
				</Popover>
			)}
		</>
	);
};

export default AmountInput;
