import { useNavigate } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

import AccountOverview from '../AccountOverview';
import { LoanTypes } from '@api/models/account';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import {
	LoanResponse,
	LoanResponseLoanTypeEnum,
} from '../../../../types/generated/pos';
import { getLoanEventOptions } from '../../utils/eventOptions';
import { useParamsAccountId } from '../../utils/useParamsAccountId';
import { useGetLoanAgreementApi } from '@api/services/accountService/useGetAgreementApi';

interface Props {
	loan: LoanResponse;
}

function LoanOverview({ loan }: Props) {
	const { loanId, progress, loanType } = loan;
	const navigate = useNavigate();

	const accountId = useParamsAccountId();
	const agreementState = useGetLoanAgreementApi(accountId);

	const eventOptions = getLoanEventOptions(loan);

	const options = [
		{
			label: 'Completed payments',
			text: `${progress.paid} of ${progress.total}`,
		},

		{
			label: `Payment schedule`,
			onClick: () => {
				track(AmplitudeEvents.PaymentSchedule_Tap, { product: eventOptions });
				navigate(`/schedule/${loanId}`);
			},
		},

		{
			label: `Payment history`,
			onClick: () => {
				track(AmplitudeEvents.PaymentHistory_Tap, { product: eventOptions });
				navigate(`/history/${loanId}`);
			},
		},

		{
			label: `${loanType === LoanTypes.POS ? 'Order' : 'Loan'} details`,
			onClick: () => {
				track(AmplitudeEvents.Order_Tap, { product: eventOptions });
				navigate(`/order/${loanId}`);
			},
		},
		{
			label: 'Signed contract',
			onClick: () => {
				if (!agreementState.data?.length) {
					return;
				}
				track(AmplitudeEvents.Agreement_Tap, {
					product: eventOptions,
					source:
						loan.loanType === LoanResponseLoanTypeEnum.CASH
							? 'CashloanMain'
							: 'POSMain',
				});
				window.open(agreementState.data[0], '_blank');
			},
		},
	];

	return <AccountOverview options={options} />;
}

export default LoanOverview;
